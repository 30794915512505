import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '@fortawesome/fontawesome-free/css/all.css';

import { obtenerConsulta, reenviarReceta } from '../../actions/consultas';

const styles = {
    imgContainer: {
        height: '120px'
    },
    profileImage: {
        height: 75, 
        width: 75,
        borderRadius: '100px',
        backgroundSize: 'cover', 
        objectFit: 'cover'
    },
    nonProfileImage: {
        backgroundColor: '#6eb9b1'
    }, 
    secondProfileImage: {
        position: 'absolute',
        left: '50px',
        top: '25px'
    },
    nonSecondProfileImage: {
        backgroundColor: '#AEAEAE'
    }, 
    infoContainer: {
        marginTop: 10,
    },
    info: {
        margin: 0,
        fontWeight: 'bold'
    },
    infoTitulo: {
        margin: 0,
    }
}

const Consulta = ({ history }) => {

    const dispatch = useDispatch();
    
    const { id } = useParams();

    const { detalles } = useSelector(state => state.consultas);

    const [consulta, setConsulta] = useState(null);

    const handleReenviar = () => {
        dispatch(reenviarReceta(id));
    }

    useEffect(() => {
        if(id){
            dispatch(obtenerConsulta(id));
        }
    }, [id]);

    useEffect(() => {
        setConsulta(detalles);
    }, [detalles]);

    return (
        consulta ? <div className="row pb-5 mt-5">
            <div className="col-md-10 offset-md-1 mt-3">
                <div className="col-md-12">
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div style={styles.imgContainer}>
                                {
                                    consulta.url_perfil_medico ? 
                                        <img src={consulta.url_perfil_medico} alt="" style={styles.profileImage} />
                                    :
                                        <div style={{...styles.profileImage, ...styles.nonProfileImage}}></div>
                                }
                                {
                                    consulta.url_perfil_paciente ? 
                                        <img src={consulta.url_perfil_paciente} alt="" style={{ ...styles.profileImage, ...styles.secondProfileImage }} />
                                    :
                                        <div style={{...styles.profileImage, ...styles.secondProfileImage, ...styles.nonSecondProfileImage}}></div>
                                }
                            </div>
                        </div>
                        <div className="col-md-12">
                            <h5>Resumen de consulta</h5>
                            <h3>{consulta.nombre_paciente} {consulta.apellido_paciente} y {consulta.nombre_medico} {consulta.apellido_medico} </h3>
                        </div>
                        <div className="col-md-12">
                            <hr />
                        </div>
                        <div className="col-md mt-3">
                            <div style={styles.infoContainer}>
                                <p style={styles.infoTitulo}>Médico</p>
                                <p style={styles.info}>{consulta.nombre_medico} {consulta.apellido_medico}</p>
                            </div>
                            <div style={styles.infoContainer}>
                                <p style={styles.infoTitulo}>Paciente</p>
                                <p style={styles.info}>{consulta.nombre_paciente} {consulta.apellido_paciente}</p>
                            </div>
                            <div style={styles.infoContainer}>
                                <p style={styles.infoTitulo}>Tipo</p>
                                <p style={styles.info}>
                                    {consulta.tipo_consulta === 0 && 'Chat'}
                                    {consulta.tipo_consulta === 1 && 'Llamada'}
                                    {consulta.tipo_consulta === 2 && 'Videollamada'}
                                </p>
                            </div>
                            <div style={styles.infoContainer}>
                                <p style={styles.infoTitulo}>Duración</p>
                                <p style={styles.info}>
                                    {consulta.duracion}
                                </p>
                            </div>
                        </div>
                        <div className="col-md mt-3">
                            <div style={styles.infoContainer}>
                                <p style={styles.infoTitulo}>Fecha</p>
                                <p style={styles.info}>
                                    {`${new Intl.DateTimeFormat("es-MX").format(new Date(consulta.fecha))}`}
                                </p>
                            </div>
                            <div style={styles.infoContainer}>
                                <p style={styles.infoTitulo}>Monto Inicial</p>
                                <p style={styles.info}>
                                    {`${new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(consulta.monto_inicial)}`}
                                </p>
                            </div>
                            <div style={styles.infoContainer}>
                                <p style={styles.infoTitulo}>Monto Final</p>
                                <p style={styles.info}>
                                    {`${new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(consulta.monto)}`}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <hr />
                        </div>
                        <div className="col-md">
                            <div style={styles.infoContainer}>
                                <p style={styles.infoTitulo}>Receta</p>
                                <p style={styles.info}>
                                    {consulta.receta ? 'Sí' : 'No'}
                                    {
                                        consulta.receta &&
                                        <button className="btn btn-link" onClick={() => handleReenviar()}>Reenviar receta por correo</button>
                                    }
                                </p>
                            </div>
                            <div style={styles.infoContainer}>
                                <p style={styles.infoTitulo}>Calificacion</p>
                                <p style={styles.info}>
                                    {consulta.calificacion ? consulta.calificacion : 'Sin calificar'}
                                </p>
                            </div>
                        </div>
                        <div className="col-md">
                            <div style={styles.infoContainer}>
                                <p style={styles.infoTitulo}>Comentarios</p>
                                <p style={styles.info}>
                                    {consulta.comentarios ? consulta.comentarios : 'Sin comentario'}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> : <div></div>
    )
}



export default Consulta;
