import { PACIENTES } from '../actions/types';

const initialState = {
    listado: [],
    detalles: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case PACIENTES.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case PACIENTES.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        case PACIENTES.EDITAR:
            return {
                ...state,
                listado: state.listado.map( paciente => paciente._id === payload._id ? payload : paciente)
            };

        default:
            return state;
    }
}