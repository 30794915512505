import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { agregarUsuario } from '../../actions/usuarios';
import PropTypes from 'prop-types';

const FormUsuario = ({ history, agregarUsuario }) => {

    const handleSubmit = async (e) => {
        e.preventDefault();
        agregarUsuario({
            ...usuarioData, 
            permisos: permisos
        })
        history.push('/administrador');
    }

    const [usuarioData, setUsuarioData] = useState({
        name: '',
        email: '',
        password: ''
    });

    const { name, email, password } = usuarioData;

    const onChange = (e) => {
        setUsuarioData({
            ...usuarioData, [e.target.name]: e.target.value
        })
    }

    // Permisos
    const [permisos, setPermisos] = useState({
        superadmin: false,
        admin: false,
        agregar_admin: false,
        editar_admin: false,
        eliminar_admin: false,
        desactivar_admin: false,
        permisos_admin: false,
        especialidades: false,
        agregar_especialidades: false,
        editar_especialidades: false,
        eliminar_especialidades: false,
        medicos: false,
        validar_medicos: false,
        perfil_medico: false,
        activar_medico: false,
        correo_medico: false,
        pacientes: false,
        perfil_paciente: false,
        activar_paciente: false,
        correo_paciente: false,
        cobros: false,
        cobros_facturas: false,
        cobros_excel: false,
        pagos_pendientes: false,
        pagos_pendientes_facturas: false,
        pagos_pendientes_excel: false,
        pagos_realizados: false,
        pagos_realizados_excel: false,
        consultas: false,
        lista_consultas: false,
        ver_consulta: false,
        colaboradores: false,
        pagos_pendientes_colaborador: false,
        pagos_pendientes_facturas_colaborador: false,
        pagos_pendientes_pagar_colaborador: false,
        pagos_pendientes_excel_colaborador: false,
        pagos_realizados_colaborador: false,
        pagos_realizados_excel_colaborador: false
    });

    const changeCategory = (e) => {
        let temp = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.dataset.type)).map(element => [element.id, e.target.checked]));
        temp[e.target.id] = e.target.checked;
        setPermisos({
            ...permisos,
            ...temp
        })
    }

    const onChangeCheckbox = (e) => {
        let temp = {};
        if (permisos.superadmin && permisos[e.target.id]) {
            temp = {
                [e.target.id]: !permisos[e.target.id],
                superadmin: false
            }
        } else {
            temp = {
                [e.target.id]: !permisos[e.target.id]
            }
        }
        setPermisos({
            ...permisos,
            ...temp
        });
    };

    useEffect(() => {
        if (!permisos.superadmin) {
            let check = Object.values(permisos);
            check.shift();
            if (check.every(element => element)) {
                setPermisos({
                    ...permisos,
                    superadmin: true,
                })
            }
        }
    }, [permisos])

    return (
        <div className="row pb-5">
            <form className="col-md-10 offset-md-1">
                <h3 className="mb-3 mt-5">Crear Administrador</h3>
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Nombre" name="name" onChange={e => onChange(e)} value={name}></input>
                </div>
                <div className="form-group">
                    <input type="email" className="form-control" placeholder="Correo" name="email" onChange={e => onChange(e)} value={email}></input>
                </div>
                <div className="form-group">
                    <input type="password" className="form-control" placeholder="Contraseña" name="password" onChange={e => onChange(e)} value={password}></input>
                </div>
                <h5>Permisos</h5>
                <div className="row mt-4">
                    <div className="col-md-12 ">
                        <div className="card p-4 bg-light">
                            <div className="form-check d-flex align-items-center">
                                <input data-type="superadministrador" className="form-check-input" type="checkbox" checked={permisos.superadmin} id="superadmin" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <div className="pl-3">
                                    <label className="form-check-label font-weight-bold" htmlFor="superadmin">
                                        Superadministrador
                                    </label>
                                    <p className="text-muted m-0">El superadministrador tiene todos los permisos</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Administradores */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check ">
                                <input data-type="administrador" className="form-check-input superadministrador" type="checkbox" checked={permisos.admin} id="admin" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="admin">
                                    Administrador
                            </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input administrador superadministrador" type="checkbox" checked={permisos.agregar_admin} id="agregar_admin" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="agregar_admin">
                                    Agregar administradores
                            </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input administrador superadministrador" type="checkbox" checked={permisos.editar_admin} id="editar_admin" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="editar_admin">
                                    Editar administradores
                            </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input administrador superadministrador" type="checkbox" checked={permisos.eliminar_admin} id="eliminar_admin" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="eliminar_admin">
                                    Eliminar administradores
                            </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input administrador superadministrador" type="checkbox" checked={permisos.desactivar_admin} id="desactivar_admin" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="desactivar_admin">
                                    Desactivar administradores
                            </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input administrador superadministrador" type="checkbox" checked={permisos.permisos_admin} id="permisos_admin" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="permisos_admin">
                                    Asignar permisos administradores
                            </label>
                            </div>
                        </div>
                    </div>
                    {/* Especialides */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="especialidades" className="form-check-input superadministrador" type="checkbox" checked={permisos.especialidades} id="especialidades" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="especialidades">
                                    Especialidades
                            </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input especialidades superadministrador" type="checkbox" checked={permisos.agregar_especialidades} id="agregar_especialidades" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="agregar_especialidades">
                                    Agregar Especialidades
                            </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input especialidades superadministrador" type="checkbox" checked={permisos.editar_especialidades} id="editar_especialidades" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="editar_especialidades">
                                    Editar especialidades
                            </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input especialidades superadministrador" type="checkbox" checked={permisos.eliminar_especialidades} id="eliminar_especialidades" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="eliminar_especialidades">
                                    Eliminar especialidades
                            </label>
                            </div>
                        </div>
                    </div>
                    {/* Medicos */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="medicos" className="form-check-input superadministrador" type="checkbox" checked={permisos.medicos} id="medicos" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="medicos">
                                    Médicos
                            </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input medicos superadministrador" type="checkbox" checked={permisos.validar_medicos} id="validar_medicos" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="validar_medicos">
                                    Validar médicos
                            </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input medicos superadministrador" type="checkbox" checked={permisos.perfil_medico} id="perfil_medico" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="perfil_medico">
                                    Ver perfil de médicos
                            </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input medicos superadministrador" type="checkbox" checked={permisos.activar_medico} id="activar_medico" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="activar_medico">
                                    Activar medicos
                            </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input medicos superadministrador" type="checkbox" checked={permisos.correo_medico} id="correo_medico" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="correo_medico">
                                    Enviar correo a medicos
                            </label>
                            </div>
                        </div>
                    </div>
                    {/* Pacientes */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="pacientes" className="form-check-input superadministrador" type="checkbox" checked={permisos.pacientes} id="pacientes" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="pacientes">
                                    Pacientes
                            </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input pacientes superadministrador" type="checkbox" checked={permisos.perfil_paciente} id="perfil_paciente" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="perfil_paciente">
                                    Ver perfil de pacientes
                            </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input pacientes superadministrador" type="checkbox" checked={permisos.activar_paciente} id="activar_paciente" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="activar_paciente">
                                    Activar/Desactivar pacientes
                            </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input pacientes superadministrador" type="checkbox" checked={permisos.correo_paciente} id="correo_paciente" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="correo_paciente">
                                    Enviar correo a pacientes
                            </label>
                            </div>
                        </div>
                    </div>
                    {/* Cobros a pacientes */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="cobros" className="form-check-input superadministrador" type="checkbox" checked={permisos.cobros} id="cobros" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="cobros">
                                    Cobros a pacientes
                            </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input cobros superadministrador" type="checkbox" checked={permisos.cobros_facturas} id="cobros_facturas" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="cobros_facturas">
                                    Enviar facturas
                            </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input cobros superadministrador" type="checkbox" checked={permisos.cobros_excel} id="cobros_excel" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="cobros_excel">
                                    Exportar a excel
                            </label>
                            </div>
                        </div>
                    </div>
                    {/* Pagos pendientes */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="pagos_pendientes" className="form-check-input superadministrador" type="checkbox" checked={permisos.pagos_pendientes} id="pagos_pendientes" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="pagos_pendientes">
                                    Pagos pendientes
                            </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input pagos_pendientes superadministrador" type="checkbox" checked={permisos.pagos_pendientes_facturas} id="pagos_pendientes_facturas" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="pagos_pendientes_facturas">
                                    Enviar facturas
                            </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input pagos_pendientes superadministrador" type="checkbox" checked={permisos.pagos_pendientes_excel} id="pagos_pendientes_excel" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="pagos_pendientes_excel">
                                    Exportar a excel
                            </label>
                            </div>
                        </div>
                    </div>
                    {/* Pagos realizados */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="pagos_realizados" className="form-check-input superadministrador" type="checkbox" checked={permisos.pagos_realizados} id="pagos_realizados" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="pagos_realizados">
                                    Pagos realizados
                            </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input pagos_realizados superadministrador" type="checkbox" checked={permisos.pagos_realizados_excel} id="pagos_realizados_excel" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="pagos_realizados_excel">
                                    Exportar a excel
                            </label>
                            </div>
                        </div>
                    </div>
                    {/* Consultas */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="consultas" className="form-check-input superadministrador" type="checkbox" checked={permisos.consultas} id="consultas" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="consultas">
                                    Consultas
                                </label>
                            </div>
                            <hr />
                            <div className="form-check ml-3">
                                <input className="form-check-input consultas superadministrador" type="checkbox" checked={permisos.lista_consultas} id="lista_consultas" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="lista_consultas">
                                    Ver lista
                            </label>
                            </div>
                            <div className="form-check ml-3">
                                <input className="form-check-input consultas superadministrador" type="checkbox" checked={permisos.ver_consulta} id="ver_consulta" onChange={onChangeCheckbox}></input>
                                <label className="form-check-label" htmlFor="ver_consulta">
                                    Ver detalle
                            </label>
                            </div>
                        </div>
                    </div>
                    {/* Colaboradores */}
                    <div className="col-md-4 mt-4">
                        <div className="card px-4 py-4">
                            <div className="form-check">
                                <input data-type="colaboradores" className="form-check-input superadministrador" type="checkbox" checked={permisos.colaboradores} id="colaboradores" onChange={(e) => {
                                    changeCategory(e)
                                }}></input>
                                <label className="form-check-label" htmlFor="colaboradores">
                                    Colaboradores
                                </label>
                            </div>
                            <hr />
                        </div>
                    </div>
                    {/* Pagos pendientes colaboradores */}
                        <div className="col-md-4 mt-4">
                            <div className="card px-4 py-4">
                                <div className="form-check">
                                    <input data-type="pagos_pendientes_colaborador" className="form-check-input superadministrador" type="checkbox" checked={permisos.pagos_pendientes_colaborador} id="pagos_pendientes_colaborador" onChange={(e) => {
                                        changeCategory(e)
                                    }}></input>
                                    <label className="form-check-label" htmlFor="pagos_pendientes_colaborador">
                                        Pagos pendientes a colaborador
                                </label>
                                </div>
                                <hr />
                                <div className="form-check ml-3">
                                    <input className="form-check-input pagos_pendientes_colaborador superadministrador" type="checkbox" checked={permisos.pagos_pendientes_facturas_colaborador} id="pagos_pendientes_facturas_colaborador" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="pagos_pendientes_facturas_colaborador">
                                        Enviar facturas
                                </label>
                                </div>
                                <div className="form-check ml-3">
                                    <input className="form-check-input pagos_pendientes_colaborador superadministrador" type="checkbox" checked={permisos.pagos_pendientes_excel_colaborador} id="pagos_pendientes_excel_colaborador" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="pagos_pendientes_excel_colaborador">
                                        Exportar a excel
                                </label>
                                </div>
                            </div>
                        </div>
                        {/* Pagos realizados colaboradores */}
                        <div className="col-md-4 mt-4">
                            <div className="card px-4 py-4">
                                <div className="form-check">
                                    <input data-type="pagos_realizados_colaborador" className="form-check-input superadministrador" type="checkbox" checked={permisos.pagos_realizados_colaborador} id="pagos_realizados_colaborador" onChange={(e) => {
                                        changeCategory(e)
                                    }}></input>
                                    <label className="form-check-label" htmlFor="pagos_realizados_colaborador">
                                        Pagos realizados a colaborador
                                </label>
                                </div>
                                <hr />
                                <div className="form-check ml-3">
                                    <input className="form-check-input pagos_realizados_colaborador superadministrador" type="checkbox" checked={permisos.pagos_realizados_excel_colaborador} id="pagos_realizados_excel_colaborador" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="pagos_realizados_excel_colaborador">
                                        Exportar a excel
                                </label>
                                </div>
                            </div>
                        </div>
                </div>
                <button type="submit" className="btn btn-primary float-right" onClick={handleSubmit}>Crear</button>
            </form>
        </div>
    )
}

FormUsuario.propTypes = {
    agregarUsuario: PropTypes.func.isRequired,
}


export default connect(null, {
    agregarUsuario
})(FormUsuario);