import React, { Fragment } from 'react';

const styles = {
    bgImg: {
        height: '40vw',
        opacity: 0.08,
        position: 'absolute',
        left: '-320px',
        top: '10vh'
    },
}

const NotFoundPage = () => (
    <Fragment>
        <div className="row d-flex align-items-center" style={{
            height: '100vh'
        }}>
            <img src="logo.png" className="d-inline-block my-4" alt="logo" style={styles.bgImg}/>
            <div className="col-md text-center">
                <h1 style={{
                    fontSize: 120,
                    color: '#42B4D9'
                }}>404</h1>
                <h5 style={{
                }}>Eso no existe :(</h5>
            </div>
        </div>
    </Fragment>
)


export default NotFoundPage;
