
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { editarUsuario } from '../../actions/auth';
import { setAlert } from '../../actions/alert';

const Perfil = ({ history }) => {

    const dispatch = useDispatch();

    const { usuario } = useSelector(state => state.auth);

    const [usuarioData, setUsuarioData] = useState([]);
    const [editingPassword, setEditingPassword] = useState(false);

    const onChange = (e) => {
        setUsuarioData({
            ...usuarioData, [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async () => {
        // Comprobamos las contraseñas
        if (editingPassword) {
            let contrasena = document.getElementById('contrasena');
            let contrasena2 = document.getElementById('contrasena2');

            if (contrasena.value !== contrasena2.value || !contrasena.value || !contrasena2.value ) {
                setAlert('Las contraseñas no coinciden', 'danger');
                return;
            }
        } 

        await dispatch(editarUsuario(usuarioData));
        await history.push('/dashboard');   
    }

    useEffect(() => {
        setUsuarioData({
            ...usuarioData,
            id: usuario._id,
            nombre: usuario.nombre,
            email: usuario.email,
            active: true,
        });
    }, [usuario]);    

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <input type="hidden" value="hola"/>
                <h3 className="mb-3 mt-5">Editar Perfil</h3>
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Nombre" name="nombre" onChange={e => onChange(e)} value={usuarioData.nombre}></input>
                </div>
                <div className="form-group">
                    <input autoComplete="new-password" type="email" className="form-control" placeholder="Correo" name="email" onChange={e => onChange(e)} value={usuarioData.email}></input>
                </div>
                <div className={editingPassword ? 'form-group d-none' : 'form-group'}>
                    <button className="btn btn-link text-primary" onClick={ (e) => {e.preventDefault(); setEditingPassword(true)}}>Cambiar contraseña</button>
                </div>
                <div className={editingPassword ? 'form-group' : 'form-group d-none'}>
                    <input autoComplete="new-password" type="password" placeholder="Nueva contraseña" id="contrasena" className="form-control"/>
                </div>
                <div className={editingPassword ? 'form-group' : 'form-group d-none'}>
                    <input autoComplete="new-password"  id="contrasena2" type="password" placeholder="Confirmar nueva contraseña" className="form-control" name="password" onChange={e => onChange(e)}/>
                </div>
                <button type="button" className="btn btn-primary float-right" onClick={handleSubmit}>Guardar</button>
            </div>
        </div>
    )
}

export default Perfil;