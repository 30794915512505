
import axios from 'axios';

import { AGREGAR_ESPECIALIDAD, EDITAR_ESPECIALIDAD, EDITANDO_ESPECIALIDAD, ELIMINAR_ESPECIALIDAD, BUSCAR_ESPECIALIDADES } from '../actions/types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

export const buscarEspecialidades = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/admin_especialidades/`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: BUSCAR_ESPECIALIDADES,
            payload: res.data.especialidades
        });
    } catch (error) {
        await dispatch(changeLoader(false));

        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarEspecialidad = (especialidad) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(especialidad);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_especialidades/nuevo/`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: AGREGAR_ESPECIALIDAD,
            payload: res.data.especialidad
        });

        await dispatch(setAlert(res.data, 'success'));
    } catch (error) {
        await dispatch(changeLoader(false));

        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarEspecialidad = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({id});

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_especialidades/eliminar`, body, config);
        await dispatch(changeLoader(false));
        
        await dispatch({
            type: ELIMINAR_ESPECIALIDAD,
            payload: {
                id
            }
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        await dispatch(changeLoader(false));

        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarEspecialidad = (especialidad) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(especialidad);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_especialidades/editar/`, body, config);
        await dispatch(changeLoader(false));
        
        await dispatch({
            type: EDITAR_ESPECIALIDAD,
            payload: especialidad.id
        });

        await dispatch(setAlert(res.data.msg, 'success'));

        await buscarEspecialidades();
    } catch (error) {
        await dispatch(changeLoader(false));
        
        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editandoEspecialidad = (id) => dispatch => {
    dispatch({
        type: EDITANDO_ESPECIALIDAD,
        payload: id
    });
}