import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { validarMedico } from '../../actions/medicos';

const Validacion = ({ medicos }) => {

    const changeValidacion = (id, status) => {
        if (status === 1) {
            validarMedico(id, 2)
        } else {
            validarMedico(id, 1)
        }
    }

    const listaDoctores = (items) => {
        return (
            <Fragment>
                {items.map(item => {
                    return (<Fragment key={item._id}>
                        <div className="row mb-3">
                            <div className="col-md-12">
                                <h6 className="my-0">{item.nombre} {item.apellido}</h6>
                                <button className="btn btn-link text-success pt-0 pl-0 ml-0" onClick={() => changeValidacion(item._id, 2)}>Validar</button>
                                <Link to={`/perfil_medico/${item._id}`}>Ver perfil completo...</Link>
                            </div>

                        </div>
                    </Fragment>)
                })}
            </Fragment>
        );
    }

    medicos = medicos.filter(item => item.validado === 0)

    return (
        <div>
            {(medicos.length > 0) ? listaDoctores(medicos) : <h6>No hay medicos nuevos</h6>}
        </div>
    )
}

export default Validacion;