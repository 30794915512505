import { CONSULTAS } from '../actions/types'

const initialState = {
    listado: [],
    detalles: null
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        case CONSULTAS.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case CONSULTAS.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        default:
            return state;
    }

}