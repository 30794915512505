import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { editarEspecialidad } from '../../actions/especialidades';

const EditEspecialidad = ({ history }) =>{

    const dispatch = useDispatch();

    const especialidades = useSelector(state => state.especialidades);
    
    const especialidad = especialidades.find( especialidad => especialidad.editing);
    
    const [ especialidadData, setEspecialidadData ] = useState({
        id: especialidad._id,
        nombre: especialidad.nombre,
        descripcion: especialidad.descripcion
    });
    
    const onChange = (e) => {
        setEspecialidadData({
            ...especialidadData, [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async () => {
        await dispatch(editarEspecialidad(especialidadData))
        await history.push('/especialidades');
    }

    return(
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Editar especialidad</h3>
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Nombre" name="nombre" onChange={e => onChange(e) } value={especialidadData.nombre}></input>
                </div>
                <div className="form-group">
                    <textarea name="descripcion" placeholder="Descripción" id="" cols="30" rows="10" className="form-control" onChange={e => onChange(e) } value={especialidadData.descripcion}></textarea>
                </div>
                <button type="button" className="btn btn-primary float-right" onClick={handleSubmit}>Guardar</button>
            </div>
        </div>
    )
}

export default EditEspecialidad;