import { PAGOS_COLABORADORES } from '../actions/types';

const initialState = {
    pagos_pendientes: [],
    pagos_realizados: [],
    pdf: null, 
    xml: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case PAGOS_COLABORADORES.BUSCAR:
            return {
                ...state,
                pagos_pendientes: payload.pagos_pendientes,
                pagos_realizados: payload.pagos_realizados
            };

        case PAGOS_COLABORADORES.PAGAR:
            return {
                ...state,
                pagos_pendientes: state.pagos_pendientes.filter( pago => pago._id !== payload.id),
                pagos_realizados: payload.pagos_realizados
            };

        case PAGOS_COLABORADORES.FACTURA:
            return {
                ...state,
                pdf: payload.url_factura_pdf, 
                xml: payload.url_factura_xml
            };

        default:
            return state;
    }
}