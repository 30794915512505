
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import '@fortawesome/fontawesome-free/css/all.css';

import { buscarPacientes, validarPaciente, enviarCorreo } from '../../actions/pacientes';
import { setAlert } from '../../actions/alert';

import Paciente from './Paciente';

const Pacientes = ({ history }) => {

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const dispatch = useDispatch();
    const permisos = useSelector(state => state.auth.usuario.permisos)
    const { listado } = useSelector(state => state.pacientes)

    const columns = [
        {
            name: 'Nombre',
            selector: 'nombre',
            sortable: true,
            cell: item => `${item.nombre} ${item.apellido}`,
        }, {
            name: 'Correo',
            selector: 'correo',
            sortable: true,
            cell: item => item.email ? `${item.email}` : 'Sin correo',
        }, {
            name: '',
            button: true,
            omit: !permisos?.correo_paciente, 
            cell: item => <button className="btn btn-link text-info" onClick={() => { handleShowModal(item._id) }}>Correo</button>,
        }, {
            name: '',
            button: true,
            omit: !permisos?.activar_paciente, 
            cell: item => <button className="btn btn-link" onClick={() => changeValidacion(item._id, item.validado)}>{(item.validado === 1) ? <span className="text-danger">Desactivar</span> : <span className="text-success">Activar</span>}</button>,
        }, {
            name: '',
            button: true,
            omit: !permisos?.perfil_paciente, 
            cell: item => <button className="btn btn-link text-primary" onClick={() => {
                setPacienteSeleccionado(item._id);
            }}> Perfil </button>,
        }
    ];

    const conditionalRowStyles = [
        {
            when: row => row._id === pacienteSeleccionado,
            style: {
                backgroundColor: '#f8f9fa !important',
            },
        }
    ];

    const changeValidacion = (id, validado) => {
        (validado === 1) ? dispatch(validarPaciente(id, 2)) : dispatch(validarPaciente(id, 1));
    }

    // Modal de correo
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setDataCorreo({
            asunto: '',
            mensaje: '',
            id: '',
        })
        setShowModal(false)
    }
    const handleShowModal = id => {
        setDataCorreo({
            ...dataCorreo, id
        })
        setShowModal(true)
    };

    // Estado del correo
    const [dataCorreo, setDataCorreo] = useState({
        asunto: '',
        mensaje: '',
        id: '',
    });
    const { asunto, mensaje } = dataCorreo;
    const onChange = (e) => {
        setDataCorreo({
            ...dataCorreo, [e.target.name]: e.target.value
        })
    }

    const handleEnviarCorreo = () => {
        dispatch(enviarCorreo(dataCorreo));
        setShowModal(false);
    }

    // Estado de medico Seleccionado
    const [pacienteSeleccionado, setPacienteSeleccionado] = useState(undefined);

    const [pacientesValidados, setPacientesValidados] = useState([])

    // Estado del filtro
    const [filterText, setFilterText] = useState('');
    const [filterValue, setFilterValue] = useState('nombre');
    const [pacientesFiltrados, setPacientesFiltrados] = useState([]);


    const onChangeFilterText = text => {
        setFilterText(text);
        text = text.toLowerCase();
        // Filtrar cédula, nombre, apellido, especialidaes, 
        setPacientesFiltrados(pacientesValidados.filter(paciente => {
            const value = (paciente[filterValue]) ? paciente[filterValue].toLowerCase() : '';
            if (value.includes(text)) {
                return true;
            } else {
                return false;
            }
        }));
    }

    useEffect(() => {
        dispatch(buscarPacientes());
    }, [])

    useEffect(() => {
        setPacientesValidados(listado.filter(paciente => paciente.validado !== 0));
    }, [listado]);

    useEffect(() => {
        setPacientesFiltrados(pacientesValidados);
    }, [pacientesValidados])



    return (
        <div>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Enviar correo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Asunto" value={asunto} name="asunto" onChange={e => onChange(e)}></input>
                        </div>
                        <div className="form-group">
                            <textarea className="form-control" placeholder="Mensaje" value={mensaje} name="mensaje" onChange={e => onChange(e)}></textarea>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleEnviarCorreo}>
                        Enviar
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="row">
                <div className={pacienteSeleccionado ? 'col-md-8 mt-5' : 'col-md-8  offset-md-2 mt-5'}>
                    <div className="col-md-12">
                        <div className="row d-flex align-items-center mb-1">
                            <div className="col-md-6">
                                <h3>Pacientes</h3>
                                <p className="text-muted mb-0 pb-0"> {pacientesValidados.length} pacientes registrados</p>
                            </div>
                            <div className="col-md-3 px-0">
                                <div className="form-group has-search">
                                    <span className="fa fa-search form-control-feedback" style={{
                                        position: 'absolute',
                                        zIndex: 2,
                                        display: 'block',
                                        width: '2.375rem',
                                        height: '2.375rem',
                                        lineHeight: '2.375rem',
                                        textAlign: 'center',
                                        pointerEvents: 'none',
                                        color: '#aaa'
                                    }}></span>
                                    <input
                                        type="text"
                                        className="form-control border-right-0" placeholder="Filtrar"
                                        value={filterText}
                                        onChange={e =>
                                            onChangeFilterText(e.target.value)
                                        }
                                        style={{
                                            paddingLeft: '2.375rem', borderTopRightRadius: 0,
                                            borderBottomRightRadius: 0
                                        }}></input>

                                </div>
                            </div>
                            <div className="col-md-3 px-0">
                                <div className="form-group">
                                    <select
                                        value={filterValue}
                                        className="form-control bg-light" style={{
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0
                                        }}
                                        onChange={e => {
                                            setFilterValue(e.target.value);
                                        }}
                                    >
                                        <option value="nombre">Nombre</option>
                                        <option value="apellido">Apellido</option>
                                        <option value="email">Correo</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                        <DataTable
                            columns={columns}
                            data={pacientesFiltrados}
                            defaultSortField="title"
                            noHeader={true}
                            pagination
                            paginationComponentOptions={paginationOptions}
                            conditionalRowStyles={conditionalRowStyles}

                        />
                    </div>
                </div>
                <div className={pacienteSeleccionado ? 'col-md-4 bg-light' : 'd-none'}>
                    <div className="col-md-12 text-right">
                        <button className="btn" onClick={() => setPacienteSeleccionado(undefined)}><i className="fa fa-times"></i></button>
                        <hr></hr>
                    </div>
                    <div className="col-md-12">
                        <Paciente id={pacienteSeleccionado}></Paciente>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Pacientes;