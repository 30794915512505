import { LOAD_DASHBOARD } from '../actions/types'

const initialState = {};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case LOAD_DASHBOARD:
            return payload
        default:
            return state;
    }

}