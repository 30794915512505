import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import '@fortawesome/fontawesome-free/css/all.css';

import { obtenerMedico, validarMedico } from '../../actions/medicos';

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    profileImage: {
        height: 150, 
        width: 150,
        borderRadius: '100px',
        backgroundSize: 'cover', 
        objectFit: 'cover'
    },
    nonProfileImage: {
        backgroundColor: '#AEAEAE'
    }, 
    titulo: { color: '#42B4D9' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoNumero: {
        fontSize: 40,
        margin: 0,
    },
    infoText: {
        margin: 0,
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    }
}

const PerfilMedico = () => {

    const dispatch = useDispatch();

    const { id } = useParams()

    const { detalles } = useSelector(state => state.medicos);

    const [medico, setMedico] = useState(null);

    // Tabla consultas
    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columnsConsulta = [
        {
            name: 'Paciente',
            selector: 'paciente',
            sortable: true,
            cell: item => `${item.nombre_paciente} ${item.apellido_paciente}`,
        }, {
            name: 'Fecha',
            selector: 'fecha',
            sortable: false,
            cell: item => new Date(item.creacion).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        }, {
            name: 'Tipo',
            selector: 'tipo_consulta',
            sortable: false,
            cell: item => `${item.tipo_consulta}`,
        }, {
            name: 'Total',
            selector: 'total',
            sortable: true,
            cell: item => new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.total),
        }, {
            name: 'Calificación',
            selector: 'calificacion',
            sortable: true,
            cell: item => item.calificacion ? `${item.calificacion}` : 'Sin calificar',
        }, {
            name: 'Comentarios',
            selector: 'comentarios',
            sortable: false,
            cell: item => item.comentarios,
        }
    ];

    const columnsCobros = [
        {
            name: 'Fecha',
            selector: 'fecha',
            sortable: false,
            cell: item => new Date(item.fecha).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        }, {
            name: 'Monto',
            selector: 'monto',
            sortable: true,
            cell: item => new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.cantidad),
        }, {
            name: 'Facturado',
            selector: 'facturado',
            cell: item => item.facturado ? 'Sí' : 'No',
        }, {
            name: 'Pagado',
            selector: 'pagado',
            cell: item => item.pagado ? 'Sí' : 'No',
        },
    ];    

    const changeValidacion = async (id, status) => {
        await dispatch(validarMedico(id, status));
        await setMedico({ ...medico, validado: status })
    }

    useEffect(() => {
        if(id){
            dispatch(obtenerMedico(id));
        }
    }, [id]);

    useEffect(() => {
        setMedico(detalles);
    }, [detalles]);

    return (
        medico ? <div className="row pt-5">
            <div className="col-md-3 px-5">
                <div className="d-flex justify-content-center">
                    {
                        medico.url_perfil ?
                            <img
                                style={styles.profileImage}
                                className="my-3 text-center"
                                src={medico.url_perfil}
                            ></img>
                        :
                            <div className="my-3 img-fluid" style={{...styles.profileImage, ...styles.nonProfileImage}}></div>
                    }
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-12 text-center">
                        <h4 className="">{medico.nombre} {medico.apellido}</h4>
                        <h6 className="text-muted">En Medpacom desde {`${new Date(medico.date).toLocaleDateString(
                            'es-MX',
                            { year: 'numeric', month: 'long', day: 'numeric' }
                        )}`}</h6>
                        <h6 className="text-muted">{medico.email}</h6>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-12">
                        <select
                            value={medico.validado}
                            className="col-md-6 offset-md-3 form-control bg-light"
                            onChange={e => {
                                changeValidacion(medico._id, e.target.value);
                            }}
                        >
                            <option value="0">En espera</option>
                            <option value="1">Activo</option>
                            <option value="2">Desactivado</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="col-md-9">
                <div className="row">
                    <div className="col-md-12 px-4">
                        <div className="row">
                            <div className="col-md">
                                <p style={styles.infoNumero}>{medico.num_consultas}</p>
                                <p style={styles.infoText}>consultas realizadas</p>
                            </div>
                            <div className="col-md">
                                <p style={styles.infoNumero}>{medico.calificacion ? medico.calificacion : 0}</p>
                                <p style={styles.infoText}>calificación promedio</p>
                            </div>
                            <div className="col-md">
                                <p style={styles.infoNumero}>{
                                    new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(medico.generado)
                                }</p>
                                <p style={styles.infoText}>generado</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">


                    <div className="col-md-12">
                        <Accordion>
                            <div className="row">
                                <div className="col-md-12">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        <h5 style={styles.titulo}>
                                            Información Personal
                                            <i className="fa fa-caret-right pl-3"></i>
                                        </h5>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <div style={styles.sectionContainer}>
                                            <p style={styles.subtitulo}>Nombre</p>
                                            <p style={styles.info}>{medico.nombre} {medico.apellido}</p>
                                            <p style={styles.subtitulo}>Fecha de nacimiento</p>
                                            <p style={styles.info}>{new Date(medico.nacimiento).toLocaleDateString('es-MX')}</p>
                                            <p style={styles.subtitulo}>Correo</p>
                                            <p style={styles.info}>{medico.email}</p>
                                            <p style={styles.subtitulo}>Celular</p>
                                            <p style={styles.info}>{medico.celular}</p>
                                            <p style={styles.subtitulo}>Especialidad</p>
                                            <p style={styles.info}>{medico.especialidad ? medico.especialidad : 'Médico general'}</p>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                                <div className="col-md-12">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                        <h5 style={styles.titulo}>
                                            Información Profesional
                                            <i className="fa fa-caret-right pl-3"></i>
                                        </h5>

                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                        <div style={styles.sectionContainer}>
                                            <div className="row">
                                                <p className="my-0 font-weight-bold col-md">Especialidad</p>
                                                <p className="my-0 font-weight-bold col-md">Cédula</p>
                                            </div>
                                            <div className="row">
                                                <span className="my-0 col-md">{medico.especialidad}</span>
                                                <span className="my-0 col-md">{medico.cedula}</span>
                                            </div>
                                            {
                                                medico.especialidades.length > 0 &&
                                                <div className="row mt-4">
                                                    <p className="my-0 font-weight-bold col-md">Subespecialidad(es)</p>
                                                    <p className="my-0 font-weight-bold col-md">Cédula</p>
                                                </div>
                                            }
                                            {
                                                medico.especialidades.length > 0 && medico.especialidades.map(especialidad =>
                                                    <div key={especialidad._id} className="row">
                                                        <span className="my-0 col-md">{especialidad.especialidad}</span>
                                                        <span className="my-0 col-md">{especialidad.cedula_especialidad}</span>
                                                    </div>
                                                )
                                            }
                                            {
                                                medico.estudios.length > 0 &&
                                                <div className="row mt-4">
                                                    <p className="my-0 font-weight-bold col-md">Estudio</p>
                                                    <p className="my-0 font-weight-bold col-md">Fecha</p>
                                                </div>
                                            }
                                            {
                                                medico.estudios.length > 0 && medico.estudios.map(estudio =>
                                                    <div key={estudio._id} className="row">
                                                        <span className="my-0 col-md">{estudio.titulo}</span>
                                                        <span className="my-0 col-md">{estudio.inicio} - {estudio.fin}</span>
                                                    </div>
                                                )
                                            }
                                            {
                                                medico.experiencia.length > 0 &&
                                                <div className="row mt-4">
                                                    <p className="my-0 font-weight-bold col-md">Cargo</p>
                                                    <p className="my-0 font-weight-bold col-md">Fecha</p>
                                                </div>
                                            }
                                            {
                                                medico.experiencia.length > 0 && medico.experiencia.map(exp =>
                                                    <div key={exp._id} className="row">
                                                        <span className="my-0 col-md">{exp.cargo}</span>
                                                        <span className="my-0 col-md">{exp.inicio} - {exp.fin}</span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                                <div className="col-md-12">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                        <h5 style={styles.titulo}>
                                            Datos Contables
                                            <i className="fa fa-caret-right pl-3"></i>
                                        </h5>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2">
                                        <div style={styles.sectionContainer}>
                                            <p style={styles.subtitulo}>RFC</p>
                                            <p style={styles.info}>{medico.cuenta.rfc}</p>
                                            <p style={styles.subtitulo}>Número de cuenta</p>
                                            <p style={styles.info}>{medico.cuenta.num_cuenta}</p>
                                            <p style={styles.subtitulo}>Titular de la cuenta</p>
                                            <p style={styles.info}>{medico.cuenta.titular}</p>
                                            <p style={styles.subtitulo}>Banco</p>
                                            <p style={styles.info}>{medico.cuenta.banco}</p>
                                            <p style={styles.subtitulo}>CLABE</p>
                                            <p style={styles.info}>{medico.cuenta.clabe}</p>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                                <div className="col-md-12">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                        <h5 style={styles.titulo}>
                                            Consultas
                                            <i className="fa fa-caret-right pl-3"></i>
                                        </h5>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="3">
                                        <div style={styles.sectionContainer}>
                                            <DataTable
                                                columns={columnsConsulta}
                                                data={medico.consultas}
                                                defaultSortField="title"
                                                noHeader={true}
                                                pagination
                                                paginationComponentOptions={paginationOptions}
                                            />
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                                <div className="col-md-12">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                        <h5 style={styles.titulo}>
                                            Pagos
                                            <i className="fa fa-caret-right pl-3"></i>
                                        </h5>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="4">
                                        <div style={styles.sectionContainer}>
                                            <DataTable
                                                columns={columnsCobros}
                                                data={medico.cobros}
                                                defaultSortField="title"
                                                noHeader={true}
                                                pagination
                                                paginationComponentOptions={paginationOptions}
                                            />
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            </div>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div> : <div></div>
    )
}




export default PerfilMedico;
