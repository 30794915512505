import axios from 'axios';

import { AGREGAR_USUARIO, EDITAR_USUARIO, EDITANDO_USUARIO, ELIMINAR_USUARIO, BUSCAR_USUARIOS } from '../actions/types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

export const buscarUsuarios = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/admin_usuarios`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: BUSCAR_USUARIOS,
            payload: res.data.users
        });
    } catch (error) {
        await dispatch(changeLoader(false));

        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarUsuario = (usuario) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(usuario);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_usuarios/nuevo/`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: AGREGAR_USUARIO,
            payload: res.data.usuario
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        await dispatch(changeLoader(false));

        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const validarUsuario = (id, status) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({
        id, status
    });

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_usuarios/validar/`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EDITAR_USUARIO,
            payload: res.data.user
        })

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        await dispatch(changeLoader(false));

        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarUsuario = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_usuarios/eliminar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: ELIMINAR_USUARIO,
            payload: {
                id
            }
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        await dispatch(changeLoader(false));

        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editandoUsuario = (id) => dispatch => {
    dispatch({
        type: EDITANDO_USUARIO,
        payload: id
    })
}

export const editarUsuario = (usuario) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({
        id: usuario._id,
        nombre: usuario.nombre, 
        email: usuario.email, 
        permisos: usuario.permisos
    });

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_usuarios/editar/`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EDITAR_USUARIO,
            payload: res.data.usuario
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        await dispatch(changeLoader(false));
        
        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}