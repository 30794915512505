import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.css';

import { obtenerMedico } from '../../actions/medicos';

const styles = {
    profileImage: {
        height: 100, 
        width: 100,
        borderRadius: '100px',
        backgroundSize: 'cover', 
        objectFit: 'cover'
    },
    nonProfileImage: {
        backgroundColor: '#AEAEAE'
    }, 
    title: {
        color: '#42B4D9',
    },
    container: {
        maxHeight: '80vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingBottom: 50
    }
}

const Medico = ({ id }) => {

    const dispatch = useDispatch();

    const { detalles } = useSelector(state => state.medicos);

    const [medico, setMedico] = useState(null);

    useEffect(() => {
        if(id){
            dispatch(obtenerMedico(id));
        }
    }, [id]);

    useEffect(() => {
        setMedico(detalles);
    }, [detalles]);

    return (
        medico ? <div style={styles.container}>
            <div className="d-flex justify-content-center">
                {
                    medico.url_perfil ?
                        <img
                            style={styles.profileImage}
                            className="my-3 text-center"
                            src={medico.url_perfil}
                        ></img>
                    :
                        <div className="my-3 img-fluid" style={{...styles.profileImage, ...styles.nonProfileImage}}></div>
                }
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-md-12 text-center">
                    <h5>{medico.nombre} {medico.apellido}</h5>
                    <h6 className="text-muted">En Medpacom desde {`${new Date(medico.date).toLocaleDateString(
                        'es-MX',
                        { year: 'numeric', month: 'long', day: 'numeric' }
                    )}`}</h6>
                    <h6 className="text-muted">{medico.email}</h6>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 my-2">
                    <p className="my-0 text-muted">Sexo</p>
                    <p className="my-0 font-weight-bold">{medico.sexo === 'm' ? 'Hombre' : 'Mujer'}</p>
                </div>
                <div className="col-md-12 my-2">
                    <p className="my-0 text-muted">Celular</p>
                    <p className="my-0 font-weight-bold">{medico.celular}</p>
                </div>
                <div className="col-md-12 my-2">
                    <p className="my-0 text-muted">Fecha de nacimiento</p>
                    <p className="my-0 font-weight-bold">
                        {new Date(medico.nacimiento).toLocaleDateString('es-MX')}
                    </p>
                </div>
                <div className="col-md-12 my-2">
                    <p className="my-0 text-muted">Especialidad</p>
                    <p className="my-0 font-weight-bold">{medico.especialidad ? medico.especialidad : 'Médico general'}</p>
                </div>
            </div>
            <div className="row mt-2 mb-0">
                <div className="col-md-12">
                    <Link style={styles.title} to={`/perfil_medico/${medico._id}`}>Ver perfil completo...</Link>
                </div>
            </div>

        </div > : <div></div>
    )
}

export default Medico;