import axios from 'axios';

import { PAGOS } from '../actions/types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

export const buscarPagos = () => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
        }

        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/admin_pagos`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PAGOS.BUSCAR,
            payload: res.data
        });
    } catch (error) {
        await dispatch(changeLoader(false));

        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const pagar = (id_pago, id_medico) => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
        }

        const body = JSON.stringify({
            id_medico, id_pago
        });

        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_pagos/pagar`, body, config);
        await dispatch(changeLoader(false));
        
        await dispatch({
            type: PAGOS.PAGAR,
            payload: {
                id: id_pago,
                pagos_realizados: res.data.pagos_realizados
            }
        });
    } catch (error) {
        await dispatch(changeLoader(false));

        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const obtenerFactura = (factura) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(factura);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_pagos/obtener_factura`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PAGOS.FACTURA,
            payload: res.data
        });
    } catch (error) {
        await dispatch(changeLoader(false));

        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}