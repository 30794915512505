import axios from 'axios';

import { MEDICOS } from '../actions/types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

export const buscarMedicos = (params = {}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        params
    }
    try {
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/admin_medicos/`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: MEDICOS.BUSCAR,
            payload: res.data.medicos
        });
    } catch (error) {
        await dispatch(changeLoader(false));

        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const obtenerMedico = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    };

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_medicos/ver`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: MEDICOS.DETALLES,
            payload: res.data.medico
        });
    } catch (error) {
        await dispatch(changeLoader(false));

        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const validarMedico = (id, status) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify({
        id, status
    });
    
    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_medicos/validar/`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: MEDICOS.EDITAR,
            payload: res.data.medico
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        await dispatch(changeLoader(false));
        
        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const enviarCorreo = (correo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(correo);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_medicos/correo`, body, config);
        await dispatch(changeLoader(false));
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        await dispatch(changeLoader(false));

        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}