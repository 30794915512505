import { COLABORADORES } from '../actions/types';

const initialState = {
    listado: [],
    detalles: null
};


export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case COLABORADORES.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case COLABORADORES.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        default:
            return state;
    }
}