import { AGREGAR_USUARIO, EDITAR_USUARIO, EDITANDO_USUARIO, ELIMINAR_USUARIO, BUSCAR_USUARIOS } from '../actions/types';

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case AGREGAR_USUARIO:
            return [
                ...state, payload
            ];
        case EDITAR_USUARIO: 
            return state.map( usuario => usuario._id === payload._id ? payload : usuario)
        case EDITANDO_USUARIO:
            state.find( usuario => usuario._id === payload).editing = true;
            return [
                ...state
            ];
        case ELIMINAR_USUARIO:
            return state.filter( user => user._id !== payload.id )
        case BUSCAR_USUARIOS:
            return payload;
        default:
            return state;
    }
}