import { LOGIN_SUCCESS, LOGIN_FAILED, USER_LOADED, AUTH_ERROR, LOGOUT, EDITAR_PERFIL } from '../actions/types'

import setAuthToken from '../utils/setAuthToken';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: true,
    usuario: {
        permisos: []
    }
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case LOGIN_SUCCESS:
            localStorage.setItem('token', payload.token);
            setAuthToken(localStorage.token);
            return {
                ...state, 
                ...payload,
                isAuthenticated: true, 
                loading: false,
            }
        case LOGIN_FAILED:
        case AUTH_ERROR:
        case LOGOUT: 
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false
            }
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false, 
                usuario: payload
            }
        case EDITAR_PERFIL: 
            return {
                ...state,
                usuario: payload
            }
        default:
            return state;
    }

}