import { MEDICOS } from '../actions/types';

const initialState = {
    listado: [],
    detalles: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case MEDICOS.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case MEDICOS.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        case MEDICOS.EDITAR:
            return {
                ...state,
                listado: state.listado.map( medico => medico._id === payload._id ? payload : medico)
            };

        default:
            return state;
    }
}