import React, { useState, useEffect } from 'react'
import { editarUsuario } from '../../actions/usuarios';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';


const EditUsuario = ({ history }) => {

    const dispatch = useDispatch();

    const usuarios = useSelector(state => state.usuarios);

    const { id } = useParams();

    const [usuario, setUsuario] = useState(usuarios.find(usuario => usuario._id == id));

    const [permisos, setPermisos] = useState({
        superadmin: usuario && usuario.permisos.superadmin,
        admin: usuario && usuario.permisos.admin,
        agregar_admin: usuario && usuario.permisos.agregar_admin,
        editar_admin: usuario && usuario.permisos.editar_admin,
        eliminar_admin: usuario && usuario.permisos.eliminar_admin,
        desactivar_admin: usuario && usuario.permisos.desactivar_admin,
        permisos_admin: usuario && usuario.permisos.permisos_admin,
        especialidades: usuario && usuario.permisos.especialidades,
        agregar_especialidades: usuario && usuario.permisos.agregar_especialidades,
        editar_especialidades: usuario && usuario.permisos.editar_especialidades,
        eliminar_especialidades: usuario && usuario.permisos.eliminar_especialidades,
        medicos: usuario && usuario.permisos.medicos,
        validar_medicos: usuario && usuario.permisos.validar_medicos,
        perfil_medico: usuario && usuario.permisos.perfil_medico,
        activar_medico: usuario && usuario.permisos.activar_medico,
        correo_medico: usuario && usuario.permisos.correo_medico,
        pacientes: usuario && usuario.permisos.pacientes,
        perfil_paciente: usuario && usuario.permisos.perfil_paciente,
        activar_paciente: usuario && usuario.permisos.activar_paciente,
        correo_paciente: usuario && usuario.permisos.correo_paciente,
        cobros: usuario && usuario.permisos.cobros,
        cobros_facturas: usuario && usuario.permisos.cobros_facturas,
        cobros_excel: usuario && usuario.permisos.cobros_excel,
        pagos_pendientes: usuario && usuario.permisos.pagos_pendientes,
        pagos_pendientes_facturas: usuario && usuario.permisos.pagos_pendientes_facturas,
        pagos_pendientes_excel: usuario && usuario.permisos.pagos_pendientes_excel,
        pagos_realizados: usuario && usuario.permisos.pagos_realizados,
        pagos_realizados_excel: usuario && usuario.permisos.pagos_realizados_excel,
        consultas: usuario && usuario.permisos.consultas,
        lista_consultas: usuario && usuario.permisos.lista_consultas,
        ver_consulta: usuario && usuario.permisos.ver_consulta,
        colaboradores: usuario && usuario.permisos.colaboradores,
        pagos_pendientes_colaborador: usuario && usuario.permisos.pagos_pendientes_colaborador,
        pagos_pendientes_facturas_colaborador: usuario && usuario.permisos.pagos_pendientes_facturas_colaborador,
        pagos_pendientes_pagar_colaborador: usuario && usuario.permisos.pagos_pendientes_pagar_colaborador,
        pagos_pendientes_excel_colaborador: usuario && usuario.permisos.pagos_pendientes_excel_colaborador,
        pagos_realizados_colaborador: usuario && usuario.permisos.pagos_realizados_colaborador,
        pagos_realizados_excel_colaborador: usuario && usuario.permisos.pagos_realizados_excel_colaborador
    });

    const onChange = (e) => {
        setUsuario({
            ...usuario, [e.target.name]: e.target.value
        })
    }

    const changeCategory = (e) => {
        let temp = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.dataset.type)).map(element => [element.id, e.target.checked]));
        temp[e.target.id] = e.target.checked;
        setPermisos({
            ...permisos,
            ...temp
        })
    }

    const onChangeCheckbox = (e) => {
        let temp = {};
        if (permisos.superadmin && permisos[e.target.id]) {
            temp = {
                [e.target.id]: !permisos[e.target.id],
                superadmin: false
            }
        } else {
            temp = {
                [e.target.id]: !permisos[e.target.id]
            }
        }
        setPermisos({
            ...permisos,
            ...temp
        });
    }

    const handleSubmit = async () => {
        await dispatch(editarUsuario(usuario));
        await history.push('/administrador');
    }

    useEffect(() => {
        setUsuario({
            ...usuario,
            permisos: permisos,
        });

        if (!permisos.superadmin) {
            let check = Object.values(permisos);
            check.shift();
            if (check.every(element => element)) {
                setPermisos({
                    ...permisos,
                    superadmin: true,
                })
            }
        }
    }, [permisos])

    if (!usuario) {
        history.push('/administrador');
        return <div></div>
    } else {
        return (
            <div className="row pb-5">
                <div className="col-md-10 offset-md-1">
                    <h3 className="mb-3 mt-5">Editar administrador</h3>
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder="Nombre" name="nombre" onChange={e => onChange(e)} value={usuario.nombre}></input>
                    </div>
                    <div className="form-group">
                        <input type="email" className="form-control" placeholder="Correo" name="email" onChange={e => onChange(e)} value={usuario.email}></input>
                    </div>
                    <h5>Permisos</h5>
                    <div className="row mt-4">
                        <div className="col-md-12 ">
                            <div className="card p-4 bg-light">
                                <div className="form-check d-flex align-items-center">
                                    <input data-type="superadministrador" className="form-check-input" type="checkbox" checked={permisos.superadmin} id="superadmin" onChange={(e) => {
                                        changeCategory(e)
                                    }}></input>
                                    <div className="pl-3">
                                        <label className="form-check-label font-weight-bold" htmlFor="superadmin">
                                            Superadministrador
                                        </label>
                                        <p className="text-muted m-0">El superadministrador tiene todos los permisos</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Administradores */}
                        <div className="col-md-4 mt-4">
                            <div className="card px-4 py-4">
                                <div className="form-check ">
                                    <input data-type="administrador" className="form-check-input superadministrador" type="checkbox" checked={permisos.admin} id="admin" onChange={(e) => {
                                        changeCategory(e)
                                    }}></input>
                                    <label className="form-check-label" htmlFor="admin">
                                        Administrador
                                </label>
                                </div>
                                <hr />
                                <div className="form-check ml-3">
                                    <input className="form-check-input administrador superadministrador" type="checkbox" checked={permisos.agregar_admin} id="agregar_admin" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="agregar_admin">
                                        Agregar administradores
                                </label>
                                </div>
                                <div className="form-check ml-3">
                                    <input className="form-check-input administrador superadministrador" type="checkbox" checked={permisos.editar_admin} id="editar_admin" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="editar_admin">
                                        Editar administradores
                                </label>
                                </div>
                                <div className="form-check ml-3">
                                    <input className="form-check-input administrador superadministrador" type="checkbox" checked={permisos.eliminar_admin} id="eliminar_admin" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="eliminar_admin">
                                        Eliminar administradores
                                </label>
                                </div>
                                <div className="form-check ml-3">
                                    <input className="form-check-input administrador superadministrador" type="checkbox" checked={permisos.desactivar_admin} id="desactivar_admin" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="desactivar_admin">
                                        Desactivar administradores
                                </label>
                                </div>
                                <div className="form-check ml-3">
                                    <input className="form-check-input administrador superadministrador" type="checkbox" checked={permisos.permisos_admin} id="permisos_admin" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="permisos_admin">
                                        Asignar permisos administradores
                                </label>
                                </div>
                            </div>
                        </div>
                        {/* Especialides */}
                        <div className="col-md-4 mt-4">
                            <div className="card px-4 py-4">
                                <div className="form-check">
                                    <input data-type="especialidades" className="form-check-input superadministrador" type="checkbox" checked={permisos.especialidades} id="especialidades" onChange={(e) => {
                                        changeCategory(e)
                                    }}></input>
                                    <label className="form-check-label" htmlFor="especialidades">
                                        Especialidades
                                </label>
                                </div>
                                <hr />
                                <div className="form-check ml-3">
                                    <input className="form-check-input especialidades superadministrador" type="checkbox" checked={permisos.agregar_especialidades} id="agregar_especialidades" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="agregar_especialidades">
                                        Agregar Especialidades
                                </label>
                                </div>
                                <div className="form-check ml-3">
                                    <input className="form-check-input especialidades superadministrador" type="checkbox" checked={permisos.editar_especialidades} id="editar_especialidades" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="editar_especialidades">
                                        Editar especialidades
                                </label>
                                </div>
                                <div className="form-check ml-3">
                                    <input className="form-check-input especialidades superadministrador" type="checkbox" checked={permisos.eliminar_especialidades} id="eliminar_especialidades" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="eliminar_especialidades">
                                        Eliminar especialidades
                                </label>
                                </div>
                            </div>
                        </div>
                        {/* Medicos */}
                        <div className="col-md-4 mt-4">
                            <div className="card px-4 py-4">
                                <div className="form-check">
                                    <input data-type="medicos" className="form-check-input superadministrador" type="checkbox" checked={permisos.medicos} id="medicos" onChange={(e) => {
                                        changeCategory(e)
                                    }}></input>
                                    <label className="form-check-label" htmlFor="medicos">
                                        Médicos
                                </label>
                                </div>
                                <hr />
                                <div className="form-check ml-3">
                                    <input className="form-check-input medicos superadministrador" type="checkbox" checked={permisos.validar_medicos} id="validar_medicos" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="validar_medicos">
                                        Validar médicos
                                </label>
                                </div>
                                <div className="form-check ml-3">
                                    <input className="form-check-input medicos superadministrador" type="checkbox" checked={permisos.perfil_medico} id="perfil_medico" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="perfil_medico">
                                        Ver perfil de médicos
                                </label>
                                </div>
                                <div className="form-check ml-3">
                                    <input className="form-check-input medicos superadministrador" type="checkbox" checked={permisos.activar_medico} id="activar_medico" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="activar_medico">
                                        Activar medicos
                                </label>
                                </div>
                                <div className="form-check ml-3">
                                    <input className="form-check-input medicos superadministrador" type="checkbox" checked={permisos.correo_medico} id="correo_medico" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="correo_medico">
                                        Enviar correo a medicos
                                </label>
                                </div>
                            </div>
                        </div>
                        {/* Pacientes */}
                        <div className="col-md-4 mt-4">
                            <div className="card px-4 py-4">
                                <div className="form-check">
                                    <input data-type="pacientes" className="form-check-input superadministrador" type="checkbox" checked={permisos.pacientes} id="pacientes" onChange={(e) => {
                                        changeCategory(e)
                                    }}></input>
                                    <label className="form-check-label" htmlFor="pacientes">
                                        Pacientes
                                </label>
                                </div>
                                <hr />
                                <div className="form-check ml-3">
                                    <input className="form-check-input pacientes superadministrador" type="checkbox" checked={permisos.perfil_paciente} id="perfil_paciente" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="perfil_paciente">
                                        Ver perfil de pacientes
                                </label>
                                </div>
                                <div className="form-check ml-3">
                                    <input className="form-check-input pacientes superadministrador" type="checkbox" checked={permisos.activar_paciente} id="activar_paciente" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="activar_paciente">
                                        Activar/Desactivar pacientes
                                </label>
                                </div>
                                <div className="form-check ml-3">
                                    <input className="form-check-input pacientes superadministrador" type="checkbox" checked={permisos.correo_paciente} id="correo_paciente" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="correo_paciente">
                                        Enviar correo a pacientes
                                </label>
                                </div>
                            </div>
                        </div>
                        {/* Cobros a pacientes */}
                        <div className="col-md-4 mt-4">
                            <div className="card px-4 py-4">
                                <div className="form-check">
                                    <input data-type="cobros" className="form-check-input superadministrador" type="checkbox" checked={permisos.cobros} id="cobros" onChange={(e) => {
                                        changeCategory(e)
                                    }}></input>
                                    <label className="form-check-label" htmlFor="cobros">
                                        Cobros a pacientes
                                </label>
                                </div>
                                <hr />
                                <div className="form-check ml-3">
                                    <input className="form-check-input cobros superadministrador" type="checkbox" checked={permisos.cobros_facturas} id="cobros_facturas" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="cobros_facturas">
                                        Enviar facturas
                                </label>
                                </div>
                                <div className="form-check ml-3">
                                    <input className="form-check-input cobros superadministrador" type="checkbox" checked={permisos.cobros_excel} id="cobros_excel" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="cobros_excel">
                                        Exportar a excel
                                </label>
                                </div>
                            </div>
                        </div>
                        {/* Pagos pendientes */}
                        <div className="col-md-4 mt-4">
                            <div className="card px-4 py-4">
                                <div className="form-check">
                                    <input data-type="pagos_pendientes" className="form-check-input superadministrador" type="checkbox" checked={permisos.pagos_pendientes} id="pagos_pendientes" onChange={(e) => {
                                        changeCategory(e)
                                    }}></input>
                                    <label className="form-check-label" htmlFor="pagos_pendientes">
                                        Pagos pendientes
                                </label>
                                </div>
                                <hr />
                                <div className="form-check ml-3">
                                    <input className="form-check-input pagos_pendientes superadministrador" type="checkbox" checked={permisos.pagos_pendientes_facturas} id="pagos_pendientes_facturas" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="pagos_pendientes_facturas">
                                        Enviar facturas
                                </label>
                                </div>
                                <div className="form-check ml-3">
                                    <input className="form-check-input pagos_pendientes superadministrador" type="checkbox" checked={permisos.pagos_pendientes_excel} id="pagos_pendientes_excel" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="pagos_pendientes_excel">
                                        Exportar a excel
                                </label>
                                </div>
                            </div>
                        </div>
                        {/* Pagos realizados */}
                        <div className="col-md-4 mt-4">
                            <div className="card px-4 py-4">
                                <div className="form-check">
                                    <input data-type="pagos_realizados" className="form-check-input superadministrador" type="checkbox" checked={permisos.pagos_realizados} id="pagos_realizados" onChange={(e) => {
                                        changeCategory(e)
                                    }}></input>
                                    <label className="form-check-label" htmlFor="pagos_realizados">
                                        Pagos realizados
                                </label>
                                </div>
                                <hr />
                                <div className="form-check ml-3">
                                    <input className="form-check-input pagos_realizados superadministrador" type="checkbox" checked={permisos.pagos_realizados_excel} id="pagos_realizados_excel" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="pagos_realizados_excel">
                                        Exportar a excel
                                </label>
                                </div>
                            </div>
                        </div>
                        {/* Consultas */}
                        <div className="col-md-4 mt-4">
                            <div className="card px-4 py-4">
                                <div className="form-check">
                                    <input data-type="consultas" className="form-check-input superadministrador" type="checkbox" checked={permisos.consultas} id="consultas" onChange={(e) => {
                                        changeCategory(e)
                                    }}></input>
                                    <label className="form-check-label" htmlFor="consultas">
                                        Consultas
                                    </label>
                                </div>
                                <hr />
                                <div className="form-check ml-3">
                                    <input className="form-check-input consultas superadministrador" type="checkbox" checked={permisos.lista_consultas} id="lista_consultas" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="lista_consultas">
                                        Ver lista
                                </label>
                                </div>
                                <div className="form-check ml-3">
                                    <input className="form-check-input consultas superadministrador" type="checkbox" checked={permisos.ver_consulta} id="ver_consulta" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="ver_consulta">
                                        Ver detalle
                                </label>
                                </div>
                            </div>
                        </div>
                        {/* Colaboradores */}
                        <div className="col-md-4 mt-4">
                            <div className="card px-4 py-4">
                                <div className="form-check">
                                    <input data-type="colaboradores" className="form-check-input superadministrador" type="checkbox" checked={permisos.colaboradores} id="colaboradores" onChange={(e) => {
                                        changeCategory(e)
                                    }}></input>
                                    <label className="form-check-label" htmlFor="colaboradores">
                                        Colaboradores
                                    </label>
                                </div>
                                <hr />
                            </div>
                        </div>
                        {/* Pagos pendientes colaboradores */}
                        <div className="col-md-4 mt-4">
                            <div className="card px-4 py-4">
                                <div className="form-check">
                                    <input data-type="pagos_pendientes_colaborador" className="form-check-input superadministrador" type="checkbox" checked={permisos.pagos_pendientes_colaborador} id="pagos_pendientes_colaborador" onChange={(e) => {
                                        changeCategory(e)
                                    }}></input>
                                    <label className="form-check-label" htmlFor="pagos_pendientes_colaborador">
                                        Pagos pendientes a colaborador
                                </label>
                                </div>
                                <hr />
                                <div className="form-check ml-3">
                                    <input className="form-check-input pagos_pendientes_colaborador superadministrador" type="checkbox" checked={permisos.pagos_pendientes_facturas_colaborador} id="pagos_pendientes_facturas_colaborador" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="pagos_pendientes_facturas_colaborador">
                                        Enviar facturas
                                </label>
                                </div>
                                <div className="form-check ml-3">
                                    <input className="form-check-input pagos_pendientes_colaborador superadministrador" type="checkbox" checked={permisos.pagos_pendientes_excel_colaborador} id="pagos_pendientes_excel_colaborador" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="pagos_pendientes_excel_colaborador">
                                        Exportar a excel
                                </label>
                                </div>
                            </div>
                        </div>
                        {/* Pagos realizados colaboradores */}
                        <div className="col-md-4 mt-4">
                            <div className="card px-4 py-4">
                                <div className="form-check">
                                    <input data-type="pagos_realizados_colaborador" className="form-check-input superadministrador" type="checkbox" checked={permisos.pagos_realizados_colaborador} id="pagos_realizados_colaborador" onChange={(e) => {
                                        changeCategory(e)
                                    }}></input>
                                    <label className="form-check-label" htmlFor="pagos_realizados_colaborador">
                                        Pagos realizados a colaborador
                                </label>
                                </div>
                                <hr />
                                <div className="form-check ml-3">
                                    <input className="form-check-input pagos_realizados_colaborador superadministrador" type="checkbox" checked={permisos.pagos_realizados_excel_colaborador} id="pagos_realizados_excel_colaborador" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="pagos_realizados_excel_colaborador">
                                        Exportar a excel
                                </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="btn btn-primary float-right" onClick={handleSubmit}>Guardar</button>
                </div>
            </div>
        )
    }
}

export default EditUsuario;