import React, { useEffect, useState, createRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { CSVLink } from "react-csv";
import { Collapse, Button, Modal } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { es } from 'react-date-range/dist/locale/index';

import { setAlert } from '../../actions/alert';
import { buscarCobros, enviarFactura, obtenerFactura } from '../../actions/cobros';

const CobrosPacientes = () => {

    const dispatch = useDispatch();

    const { listado, pdf, xml } = useSelector(state => state.cobros);
    const permisos = useSelector(state => state.auth.usuario.permisos);

    // Modal de factura
    const [showModal, setShowModal] = useState(false);

    // Estado del correo
    let facturaPdf = createRef();
    let facturaXml = createRef();

    const [dataCorreo, setDataCorreo] = useState({
        id_paciente: '',
        id_recarga: '',
        status: undefined,
        pdf: undefined,
        xml: undefined,
    });

    // Factura enviada
    const [sentModal, setSentModal] = useState(false);
    const [urlPDF, setUrlPDF] = useState(null);
    const [urlXML, setUrlXML] = useState(null);

    // Filtros
    const [filtrosVisible, setFiltrosVisible] = useState(false);
    const [nombre, setNombre] = useState('');
    const [min, setMin] = useState(undefined);
    const [max, setMax] = useState(undefined);
    const [dateRange, setDateRange] = useState({
        changed: false,
        selection: {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }
    });

    const [paypal, setPaypal] = useState(true);
    const [conekta, setConekta] = useState(true);
    const [cobrosFiltrados, setCobrosFiltrados] = useState([]);

    // Tabla
    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    let columns = [{
        name: 'Nombre',
        selector: 'nombre',
        sortable: true,
        cell: item => `${item.nombre} ${item.apellido}`,
    }, {
        name: 'Correo',
        selector: 'correo',
        sortable: true,
        cell: item => `${item.email}`,
        grow: 2
    }, {
        name: 'Monto',
        selector: 'monto',
        sortable: true,
        cell: item => `$${item.cantidad}`,
    }, {
        name: 'Método',
        selector: 'metodo',
        sortable: false,
        cell: item => item.metodo === 2 ? 'PayPal' : 'Tarjeta bancaria',
    }, {
        name: 'Fecha',
        selector: 'fecha',
        sortable: true,
        cell: item => {
            const date = new Date(item.fecha);
            return `${date.getDate()}/${date.getUTCMonth()}/${date.getFullYear()}`
        },
    }, {
        name: 'Factura',
        omit: !permisos?.cobros_facturas, 
        cell: item => {
            if (item.factura === 1) {
                return <button className="btn btn-link text-danger p-0 m-0" onClick={() => {
                    handleShowModal(item.id_paciente, item._id);
                }}> Pendiente </button>
            } else if (item.factura === 2) {
                return <button className="btn btn-link text-success p-0 m-0" onClick={() => {
                    handleShowSentModal(item.id_paciente, item._id);
                }}> Enviada </button>
            } else if (item.factura === 0) {
                return <button className="btn btn-link text-muted p-0 m-0"> No solicitada </button>
            }
        }
    }];    

    // Modal de factura
    const handleCloseModal = () => {
        setDataCorreo({
            id_paciente: '',
            id_recarga: '',
            status: undefined,
            pdf: undefined,
            xml: undefined,
        })
        setShowModal(false)
    }

    const handleCloseSentModal = () => {
        setDataCorreo({
            id_paciente: '',
            id_recarga: '',
            status: undefined,
            pdf: undefined,
            xml: undefined,
        })
        setSentModal(false)
    }

    const handleShowModal = (id_paciente, id_recarga) => {
        setDataCorreo({
            ...dataCorreo, id_paciente, id_recarga, status: 1
        });
        setShowModal(true)
    }

    // Enviar factura
    const onChangeFacturaPdf = () => {
        setDataCorreo({
            ...dataCorreo,
            pdf: facturaPdf.current.files[0],
        })
    }

    const onChangeFacturaXml = () => {
        setDataCorreo({
            ...dataCorreo,
            xml: facturaXml.current.files[0],
        })
    }

    const enviarCorreo = () => {
        let formData = new FormData();
        const { id_paciente, id_recarga, status, pdf, xml } = dataCorreo;
        formData.append('id_paciente', id_paciente);
        formData.append('id_recarga', id_recarga);
        formData.append('status', status);
        formData.append('pdf', pdf);
        formData.append('xml', xml);
        dispatch(enviarFactura(formData));
        setShowModal(false);
    }

    // Factura enviada
    const handleShowSentModal = async (id_paciente, id_recarga) => {
        await setDataCorreo({ ...dataCorreo, id_paciente, id_recarga, status: 1 });
        await dispatch(obtenerFactura({ id_paciente, id_recarga }));
        await setSentModal(true);
    };

    // Filtros
    const filtrar = () => {
        let cobrosTemp = listado;
        if (nombre) {
            cobrosTemp = cobrosTemp.filter(cobro => {
                const value = `${cobro['nombre'].toLowerCase()} ${cobro['apellido'].toLowerCase()}`;
                if (value.includes(nombre.toLowerCase())) {
                    return true;
                } else {
                    return false;
                }
            })
        }
        if (max) {
            cobrosTemp = cobrosTemp.filter(cobro => {
                if (cobro.cantidad <= max) {
                    return true;
                } else {
                    return false;
                }
            })
        }
        if (min) {
            cobrosTemp = cobrosTemp.filter(cobro => {
                if (cobro.cantidad >= min) {
                    return true;
                } else {
                    return false;
                }
            })
        }
        if (!conekta) {
            cobrosTemp = cobrosTemp.filter(cobro =>
                cobro.metodo === 1 ? false : true
            )
        }
        if (!paypal) {
            cobrosTemp = cobrosTemp.filter(cobro =>
                cobro.metodo === 2 ? false : true
            )
        }
        if (dateRange.changed) {
            cobrosTemp = cobrosTemp.filter(cobro => {
                if (dateRange.selection.startDate <= new Date(cobro.fecha) && new Date(cobro.fecha) <= dateRange.selection.endDate) {
                    return false;
                } else {
                    return true;
                }
            })
        }
        setCobrosFiltrados(cobrosTemp);
    }

    const onChangeNombre = text => {
        setNombre(text);
    }

    const onChangeRangeMax = number => {
        setMax(number);
    }

    const onChangeRangeMin = number => {
        setMin(number);
    }

    const onChangeDateRange = range => {
        setDateRange({
            changed: true,
            selection: range.selection
        });
    }

    const limpiarFiltros = () => {
        setNombre('');
        setMin('');
        setMax('');
        setCobrosFiltrados(listado);
        setDateRange({
            changed: false,
            selection: {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            }
        });
    }

    useEffect(() => {
        dispatch(buscarCobros());
    }, []);

    useEffect(() => {
        filtrar();
    }, [listado]);

    useEffect(() => {
        setUrlPDF(pdf);
        setUrlXML(xml);
    }, [pdf, xml]);

    return (
        <Fragment>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Enviar factura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label className="col-md-12" htmlFor="facturaPdf">PDF</label>
                            <input className="col-md-12" name="facturaPdf" id="facturaPDf" type="file" ref={facturaPdf} onChange={onChangeFacturaPdf} />
                        </div>
                        <div className="form-group">
                            <label className="col-md-12" htmlFor="facturaXml">XML</label>
                            <input className="col-md-12" name="facturaXml" id="facturaXml" type="file" ref={facturaXml}
                                onChange={onChangeFacturaXml} />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={enviarCorreo}>
                        Enviar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de Factura enviada */}
            <Modal show={sentModal} onHide={handleCloseSentModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Reenviar factura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>Archivos enviados anteriormente</h6>
                    <div className="d-flex justify-content-around py-3">
                        <a href={urlPDF} download>
                            <i className="fa fa-file-pdf px-2"></i>
                            PDF
                        </a>
                        <a href={urlXML} download>
                            <i className="fa fa-file-code px-2"></i>
                            XML
                        </a>
                    </div>
                    <hr />
                    <form>
                        <h6>Enviar otro correo</h6>
                        <div className="form-group">
                            <label className="col-md-12" htmlFor="facturaPdf">PDF</label>
                            <input className="col-md-12" name="facturaPdf" id="facturaPDf" type="file" ref={facturaPdf} onChange={onChangeFacturaPdf} />
                        </div>
                        <div className="form-group">
                            <label className="col-md-12" htmlFor="facturaXml">XML</label>
                            <input className="col-md-12" name="facturaXml" id="facturaXml" type="file" ref={facturaXml}
                                onChange={onChangeFacturaXml} />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSentModal}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={enviarCorreo}>
                        Enviar
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="row pb-5">
                <div className="col-md-10 offset-md-1 mt-3">
                    <div className="col-md-12 my-3">
                        <Button
                            onClick={() => setFiltrosVisible(!filtrosVisible)}
                            variant="link"
                            aria-controls="filtros"
                            aria-expanded={filtrosVisible}
                            className="m-0 p-0 text-secondary"
                        >
                            Mostrar filtros
                            {
                                filtrosVisible ?
                                    <i className="fa fa-caret-down px-2"></i> :
                                    <i className="fa fa-caret-right px-2"></i>
                            }
                        </Button>

                        <Collapse in={filtrosVisible}>
                            <div className="row mt-4" id="filtros">
                                <div className="col-md-3">
                                    <div className="form-group my-0 has-search">
                                        <label className="font-weight-bold" htmlFor="nombreInput">Nombre</label>
                                        <input
                                            id="nombreInput"
                                            type="text"
                                            className="form-control" placeholder="Nombre"
                                            onChange={e =>
                                                onChangeNombre(e.target.value)
                                            }
                                            value={nombre}></input>
                                    </div>
                                    <hr className="col-md-12" />
                                    <div className="form-group my-0 has-search">
                                        <label className="font-weight-bold">Monto</label>
                                        <input
                                            type="text"
                                            className="form-control" placeholder="Mínimo"
                                            onChange={e =>
                                                onChangeRangeMin(e.target.value)
                                            }
                                            value={min}>
                                        </input>
                                        <input
                                            type="text"
                                            className="form-control mt-1" placeholder="Máximo"
                                            onChange={e =>
                                                onChangeRangeMax(e.target.value)
                                            }
                                            value={max}>
                                        </input>
                                    </div>
                                    <hr className="col-md-12" />
                                    <div className="form-group my-0 has-search">
                                        <label className="font-weight-bold">Método</label>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="conekta"
                                                checked={conekta}
                                                onChange={() => setConekta(!conekta)}
                                            ></input>
                                            <label className="form-check-label" htmlFor="conekta">
                                                Tarjeta bancaria
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="paypal"
                                                checked={paypal}
                                                onChange={() => setPaypal(!paypal)}
                                            ></input>
                                            <label className="form-check-label" htmlFor="paypal">
                                                PayPal
                                            </label>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-9">
                                    <label className="font-weight-bold col-md-12" htmlFor="nombreInput">Fecha</label>
                                    <DateRangePicker
                                        ranges={[dateRange.selection]}
                                        onChange={range => onChangeDateRange(range)}
                                        locale={es}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <Button
                                        onClick={filtrar}
                                        variant="primary"
                                        className="px-3"
                                    >
                                        Filtrar
                                    </Button>
                                    <Button
                                        onClick={limpiarFiltros}
                                        variant="link"
                                        className="px-3"
                                    >
                                        Limpiar filtros
                                    </Button>
                                </div>
                                <hr className="col-md-12" />
                            </div>
                        </Collapse>
                    </div>
                    <div className="col-md-12">
                        <div className="row d-flex align-items-center mb-4">
                            <div className="col-md-6">
                                <h3>Cobros</h3>
                            </div>
                            {
                                (permisos && permisos.cobros_excel) &&
                                <div className="col-md-12">
                                    <CSVLink
                                        filename={'MedpacomCobros'}
                                        data={cobrosFiltrados}
                                    >
                                        {filtrosVisible ? 'Exportar filtrados' : 'Exportar a Excel'}
                                    </CSVLink>
                                </div>
                            }
                        </div>

                        <DataTable
                            highlightOnHover
                            data={cobrosFiltrados}
                            columns={columns}
                            defaultSortField="title"
                            noHeader={true}
                            pagination
                            paginationComponentOptions={paginationOptions}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


export default CobrosPacientes;