import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { CSVLink } from "react-csv";
import { Collapse, Button, Modal } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { es } from 'react-date-range/dist/locale/index';

import { setAlert } from '../../actions/alert';
import { buscarPagos, pagar, obtenerFactura } from '../../actions/pagos';

const PagosMedicosPendientes = () => {

    const dispatch = useDispatch()

    const { pagos_pendientes, pdf, xml} = useSelector(state => state.pagos);
    const permisos = useSelector(state => state.auth.usuario.permisos);

    // Factura enviada
    const [confirmacion, setConfirmacion] = useState(false);
    const [sentModal, setSentModal] = useState(false);
    const [infoModal, setInfoModal] = useState(false);
    const [urlPDF, setUrlPDF] = useState(null);
    const [urlXML, setUrlXML] = useState(null);

    // Filtros
    const [filtrosVisible, setFiltrosVisible] = useState(false);
    const [nombre, setNombre] = useState('');
    const [min, setMin] = useState(undefined);
    const [max, setMax] = useState(undefined);
    const [dateRange, setDateRange] = useState({
        changed: false,
        selection: {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }
    });

    const [paypal, setPaypal] = useState(true);
    const [conekta, setConekta] = useState(true);
    const [pagosFiltrados, setPagosFiltrados] = useState([]);
    const [pagoSeleccionado, setPagoSeleccionado] = useState(undefined);

    // Tabla
    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [{
        name: 'Nombre',
        selector: 'nombre',
        sortable: true,
        cell: item => `${item.nombre} ${item.apellido}`,
    }, {
        name: 'Correo',
        selector: 'correo',
        sortable: true,
        cell: item => `${item.email}`,
        grow: 2
    }, {
        name: 'Monto',
        selector: 'monto',
        sortable: true,
        cell: item => `$${item.cantidad}`,
    }, {
        name: 'Monto de factura',
        selector: 'monto_factura',
        sortable: true,
        cell: item => `$${item.cantidad_factura}`,
    }, {
        name: 'Honorarios',
        selector: 'honorarios',
        sortable: true,
        cell: item => `$${item.honorarios}`,
    }, {
        name: 'Fecha',
        selector: 'fecha',
        sortable: true,
        cell: item => new Date(item.fecha).toLocaleDateString(
            'es-MX',
            { year: 'numeric', month: 'long', day: 'numeric' }
        ),
    }, {
        name: 'Factura',
        cell: item => {
            if (item.facturado) {
                return <button className="btn btn-link text-success p-0 m-0" onClick={() => {
                    handleShowSentModal(item.id_medico, item._id);
                }}> Enviada </button>
            } else {
                return <button className="btn btn-link text-muted p-0 m-0">Pendiente</button>
            }
        }
    }, {
        name: 'Estado',
        omit: !permisos?.pagos_pendientes_facturas, 
        cell: item => {
            if (item.pagado) {
                return <button className="btn btn-link text-success p-0 m-0">Pagado</button>
            } else {
                return <button className="btn btn-link text-info p-0 m-0" onClick={() => handlePagar(item)}>Pagar</button>
            }
        }
    }];

    // Pagar
    const handlePagar = item => {
        setInfoModal(true);
        setPagoSeleccionado(item);
    }

    const handlePago = () => {
        dispatch(pagar(pagoSeleccionado._id, pagoSeleccionado.id_medico))
        setInfoModal(false);
    }

    // Factura
    const handleShowSentModal = async (id_medico, id_pago) => {
        await dispatch(obtenerFactura({ id_medico, id_pago }));
        await setSentModal(true)
    }

    // Filtros
    const filtrar = () => {
        let pagosTemp = pagos_pendientes;
        if (nombre) {
            pagosTemp = pagosTemp.filter(pago => {
                const value = `${pago['nombre'].toLowerCase()} ${pago['apellido'].toLowerCase()}`;
                if (value.includes(nombre.toLowerCase())) {
                    return true;
                } else {
                    return false;
                }
            })
        }
        if (max) {
            pagosTemp = pagosTemp.filter(pago => {
                if (pago.cantidad <= max) {
                    return true;
                } else {
                    return false;
                }
            })
        }
        if (min) {
            pagosTemp = pagosTemp.filter(pago => {
                if (pago.cantidad >= min) {
                    return true;
                } else {
                    return false;
                }
            })
        }
        if (!conekta) {
            pagosTemp = pagosTemp.filter(pago =>
                pago.metodo === 1 ? false : true
            )
        }
        if (!paypal) {
            pagosTemp = pagosTemp.filter(pago =>
                pago.metodo === 2 ? false : true
            )
        }
        if (dateRange.changed) {
            pagosTemp = pagosTemp.filter(pago => {
                if (dateRange.selection.startDate <= new Date(pago.fecha) && new Date(pago.fecha) <= dateRange.selection.endDate) {
                    return false;
                } else {
                    return true;
                }
            })
        }
        setPagosFiltrados(pagosTemp);
    }

    const onChangeNombre = text => {
        setNombre(text);
    }

    const onChangeRangeMax = number => {
        setMax(number);
    }

    const onChangeRangeMin = number => {
        setMin(number);
    }

    const onChangeDateRange = range => {
        setDateRange({
            changed: true,
            selection: range.selection
        });
    }

    const limpiarFiltros = () => {
        setNombre('');
        setMin('');
        setMax('');
        setPagosFiltrados(pagos_pendientes);
        setDateRange({
            changed: false,
            selection: {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            }
        });
    }

    useEffect(() => {
        dispatch(buscarPagos());
    }, []);

    useEffect(() => {
        filtrar();
    }, [pagos_pendientes]);

    useEffect(() => {
        setUrlPDF(pdf);
        setUrlXML(xml);
    }, [pdf, xml]);

    return (
        <Fragment>
            {/* Modal de Factura enviada */}
            <Modal show={sentModal} onHide={() => setSentModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Factura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>Archivos enviados por el médico</h6>
                    <div className="d-flex justify-content-around py-3">
                        <a href={urlPDF} download>
                            <i className="fa fa-file-pdf px-2"></i>
                            PDF
                        </a>
                        <a href={urlXML} download>
                            <i className="fa fa-file-code px-2"></i>
                            XML
                        </a>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSentModal(false)}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={infoModal} onHide={() => {
                setInfoModal(false)
                setConfirmacion(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Información de pago</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        pagoSeleccionado && <div>
                            <p className="my-0 font-weight-bold">Titular</p>
                            <p className="mt-0 mb-2">{pagoSeleccionado.titular}</p>
                            <p className="my-0 font-weight-bold">RFC</p>
                            <p className="mt-0 mb-2">{pagoSeleccionado.rfc}</p>
                            <p className="my-0 font-weight-bold">Banco</p>
                            <p className="mt-0 mb-2">{pagoSeleccionado.banco}</p>
                            <p className="my-0 font-weight-bold">Número de cuenta</p>
                            <p className="mt-0 mb-2">{pagoSeleccionado.num_cuenta}</p>
                            <p className="my-0 font-weight-bold">Clabe</p>
                            <p className="mt-0 mb-2">{pagoSeleccionado.clabe}</p>

                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        confirmacion ?
                            <Button variant="primary" onClick={handlePago}>
                                Sí, realizar pago
                            </Button>
                            :
                            <Button variant="primary" onClick={() => setConfirmacion(true)}>
                                Realizar pago
                            </Button>
                    }
                </Modal.Footer>
            </Modal>

            <div className="row pb-5">
                <div className="col-md-10 offset-md-1 mt-3">
                    <div className="col-md-12 my-3">
                        <Button
                            onClick={() => setFiltrosVisible(!filtrosVisible)}
                            variant="link"
                            aria-controls="filtros"
                            aria-expanded={filtrosVisible}
                            className="m-0 p-0 text-secondary"
                        >
                            Mostrar filtros
                            {
                                filtrosVisible ?
                                    <i className="fa fa-caret-down px-2"></i> :
                                    <i className="fa fa-caret-right px-2"></i>
                            }
                        </Button>

                        <Collapse in={filtrosVisible}>
                            <div className="row mt-4" id="filtros">
                                <div className="col-md-3">
                                    <div className="form-group my-0 has-search">
                                        <label className="font-weight-bold" htmlFor="nombreInput">Nombre</label>
                                        <input
                                            id="nombreInput"
                                            type="text"
                                            className="form-control" placeholder="Nombre"
                                            onChange={e =>
                                                onChangeNombre(e.target.value)
                                            }
                                            value={nombre}></input>
                                    </div>
                                    <hr className="col-md-12" />
                                    <div className="form-group my-0 has-search">
                                        <label className="font-weight-bold">Monto</label>
                                        <input
                                            type="text"
                                            className="form-control" placeholder="Mínimo"
                                            onChange={e =>
                                                onChangeRangeMin(e.target.value)
                                            }
                                            value={min}>
                                        </input>
                                        <input
                                            type="text"
                                            className="form-control mt-1" placeholder="Máximo"
                                            onChange={e =>
                                                onChangeRangeMax(e.target.value)
                                            }
                                            value={max}>
                                        </input>
                                    </div>
                                    <hr className="col-md-12" />
                                    <div className="form-group my-0 has-search">
                                        <label className="font-weight-bold">Método</label>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="conekta"
                                                checked={conekta}
                                                onChange={() => setConekta(!conekta)}
                                            ></input>
                                            <label className="form-check-label" htmlFor="conekta">
                                                Tarjeta bancaria
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="paypal"
                                                checked={paypal}
                                                onChange={() => setPaypal(!paypal)}
                                            ></input>
                                            <label className="form-check-label" htmlFor="paypal">
                                                PayPal
                                            </label>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-9">
                                    <label className="font-weight-bold col-md-12" htmlFor="nombreInput">Fecha</label>
                                    <DateRangePicker
                                        ranges={[dateRange.selection]}
                                        onChange={range => onChangeDateRange(range)}
                                        locale={es}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <Button
                                        onClick={filtrar}
                                        variant="primary"
                                        className="px-3"
                                    >
                                        Filtrar
                                    </Button>
                                    <Button
                                        onClick={limpiarFiltros}
                                        variant="link"
                                        className="px-3"
                                    >
                                        Limpiar filtros
                                    </Button>
                                </div>
                                <hr className="col-md-12" />
                            </div>
                        </Collapse>
                    </div>

                    <div className="col-md-12">
                        <div className="row d-flex align-items-center mb-4">
                            <div className="col-md-6">
                                <h3>Pagos Pendientes</h3>
                            </div>

                            {
                                (permisos && permisos.pagos_pendientes_excel) &&
                                <div className="col-md-12">
                                    <CSVLink
                                        filename={'MedpacomCobros.xls'}
                                        data={pagosFiltrados}
                                    >
                                        {filtrosVisible ? 'Exportar filtrados' : 'Exportar a Excel'}
                                    </CSVLink>
                                </div>
                            }

                        </div>
                        <DataTable
                            highlightOnHover
                            data={pagosFiltrados}
                            columns={columns}
                            defaultSortField="title"
                            noHeader={true}
                            pagination
                            paginationComponentOptions={paginationOptions}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


export default PagosMedicosPendientes;