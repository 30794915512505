import { COBROS } from '../actions/types';

const initialState = {
    listado: [],
    pdf: null, 
    xml: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case COBROS.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case COBROS.FACTURA:
            return {
                ...state,
                pdf: payload.url_factura_pdf, 
                xml: payload.url_factura_xml
            };

        default:
            return state;
    }
}