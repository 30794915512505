import { combineReducers } from 'redux';
import alert from './alert'
import auth from './auth'
import especialidades from './especialidades'
import usuarios from './usuarios'
import medicos from './medicos'
import pacientes from './pacientes'
import colaboradores from './colaboradores'
import cobros from './cobros'
import pagos from './pagos'
import pagos_colaboradores from './pagos_colaboradores'
import dashboard from './dashboard'
import consultas from './consultas'
import loader from './loader'

export default combineReducers({
    alert, auth, especialidades, usuarios, medicos, pacientes, colaboradores, cobros, pagos, pagos_colaboradores, dashboard, consultas, loader
})