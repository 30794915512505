import React, { useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import '@fortawesome/fontawesome-free/css/all.css';

import { obtenerPaciente, validarPaciente } from '../../actions/pacientes';

const styles = {
    profileImage: {
        height: 150, 
        width: 150,
        borderRadius: '100px',
        backgroundSize: 'cover', 
        objectFit: 'cover'
    },
    nonProfileImage: {
        backgroundColor: '#AEAEAE'
    }, 
    secondProfileImage: {
        height: 30, 
        width: 30,
        borderRadius: '100px',
        backgroundSize: 'cover', 
        objectFit: 'cover'
    },
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#42B4D9' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoNumero: {
        fontSize: 40,
        margin: 0,
    },
    infoText: {
        margin: 0,
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    }
}

const PerfilPaciente = ({ history }) => {

    const dispatch = useDispatch();

    const { id } = useParams();

    const { detalles } = useSelector(state => state.pacientes);

    const [paciente, setPaciente] = useState(null);

    // Tabla consultas
    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Fecha',
            selector: 'fecha',
            sortable: false,
            cell: item => new Date(item.fecha).toLocaleDateString(
                'es-MX',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ),
        }, {
            name: 'Monto',
            selector: 'monto',
            sortable: true,
            cell: item => new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.cantidad),
        }, {
            name: 'Metodo',
            selector: 'metodo',
            cell: item => item.metodo === 2 ? 'PayPal' : 'Tarjeta bancaria',
        }, {
            name: 'Factura',
            selector: 'facturado',
            cell: item => {
                if (item.factura === 0) {
                    return 'No solicitada'
                } else if (item.factura === 1) {
                    return 'Pendiente'
                } else {
                    return 'Enviada'
                }
            },
        }
    ]

    const handleChangeValidation = () => {
        let value = 1;
        if (paciente.validado === 1) value = 2;
        dispatch(validarPaciente(id, value));
        setPaciente({ ...paciente, validado: value });
    }

    const navigate = async (id) => {
        await setPaciente(null);
        await history.push(`/perfil_paciente/${id}`);
    }

    useEffect(() => {
        if(id){
            dispatch(obtenerPaciente(id));
        }
    }, [id]);

    useEffect(() => {
        setPaciente(detalles);
    }, [detalles]);

    return (
        paciente ? <div className="row py-5">
            <div className="col-md-3 px-5">
                <div className="d-flex justify-content-center">
                    {
                        paciente.url_perfil ?
                            <img
                                style={styles.profileImage}
                                className="my-3 text-center"
                                src={paciente.url_perfil}
                            ></img>
                        :
                            <div className="my-3 img-fluid" style={{...styles.profileImage, ...styles.nonProfileImage}}></div>
                    }
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-12 text-center">
                        <h4 className="">{paciente.nombre} {paciente.apellido}</h4>
                        <h6 className="text-muted">En Medpacom desde {`${new Date(paciente.date).toLocaleDateString(
                            'es-MX',
                            { year: 'numeric', month: 'long', day: 'numeric' }
                        )}`}</h6>
                        <h6 className="text-muted">{paciente.email}</h6>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-12 text-center">
                        <button className="btn btn-link col-md-6 md-offset-3" onClick={handleChangeValidation}>{(paciente.validado === 1) ? <span className="text-danger">Desactivar</span> : <span className="text-success">Activar</span>}</button>
                    </div>
                </div>
            </div>
            <div className="col-md-9">
                <div className="row">
                    <div className="col-md-12">
                        {!paciente.titular &&
                            <button className="btn btn-link" style={styles.titulo} onClick={() => navigate(paciente.familiares[0]._id)}>
                                <i className="fa fa-caret-left pr-3"></i>
                                Volver a la cuenta titular...
                            </button>
                        }
                    </div>
                    <div className="col-md-12 px-4">
                        <div className="row">
                            <div className="col-md">
                                {
                                    paciente.titular &&
                                    <Fragment>
                                        < p style={styles.infoNumero}>{
                                            new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(paciente.medpacash)
                                        }</p>
                                        <p style={styles.infoText}>medpacash</p>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-12">
                        <Accordion defaultActiveKey="0">
                            <div className="row">
                                <div className="col-md-12">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        <h5 style={styles.titulo}>
                                            Información Personal
                                            <i className="fa fa-caret-right pl-3"></i>
                                        </h5>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <div style={styles.sectionContainer}>
                                            <p style={styles.subtitulo}>Nombre</p>
                                            <p style={styles.info}>{paciente.nombre} {paciente.apellido}</p>
                                            <p style={styles.subtitulo}>Fecha de nacimiento</p>
                                            <p style={styles.info}>{new Date(paciente.nacimiento).toLocaleDateString('es-MX')}</p>
                                            <p style={styles.subtitulo}>Correo</p>
                                            <p style={styles.info}>{paciente.email}</p>
                                            <p style={styles.subtitulo}>Celular</p>
                                            <p style={styles.info}>{paciente.celular}</p>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                                {paciente.titular &&
                                    <div className="col-md-12">
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                            <h5 style={styles.titulo}>
                                                Familiares
                                            <i className="fa fa-caret-right pl-3"></i>
                                            </h5>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="1">
                                            <div style={styles.sectionContainer}>
                                                <div className="row">
                                                    {paciente.familiares.length > 0 &&
                                                        paciente.familiares.map(familiar =>
                                                            familiar &&
                                                            <div
                                                                className="mx-3 d-flex flex-column my-3 align-items-center"
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }}
                                                                key={familiar._id}
                                                                onClick={() => navigate(familiar._id)}
                                                            >
                                                                {
                                                                    familiar.url_perfil ?
                                                                        <img
                                                                            style={styles.secondProfileImage}
                                                                            className="my-2 img-fluid"
                                                                            src={familiar.url_perfil}
                                                                        ></img>
                                                                    :
                                                                        <div className="my-2 img-fluid" style={{...styles.secondProfileImage, ...styles.nonProfileImage}}></div>
                                                                }
                                                                <p className="my-0">{familiar.nombre}</p>
                                                                <p className="my-0 text-muted"> {familiar.parentesco}</p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                }

                                {paciente.titular &&
                                    <div className="col-md-12">
                                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                            <h5 style={styles.titulo}>
                                                Recargas
                                            <i className="fa fa-caret-right pl-3"></i>
                                            </h5>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="2">
                                            <div style={styles.sectionContainer}>
                                                <DataTable
                                                    columns={columns}
                                                    data={paciente.recargas.reverse()}
                                                    defaultSortField="title"
                                                    noHeader={true}
                                                    pagination
                                                    paginationComponentOptions={paginationOptions}
                                                />
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                }
                            </div>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div > : <div></div>
    )
}



export default PerfilPaciente;
