
import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { buscarUsuarios, eliminarUsuario, editandoUsuario, validarUsuario } from '../../actions/usuarios';

const Usuarios = ({ history }) => {

    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.usuario);
    const { permisos } = useSelector(state => state.auth.usuario);
    const usuarios = useSelector(state => state.usuarios);

    const tableRow = (items) => {
        return (
            <tbody>
                {items.map((item) => {
                    let fecha = new Date(item.date)
                    if (user && user._id !== item._id) {
                        return (<Fragment key={item._id}>
                            <tr>
                                <td>{item.nombre}</td>
                                <td>{item.email}</td>
                                <td>{fecha.getDay()}/{fecha.getMonth() + 1}/{fecha.getFullYear()}</td>
                                {
                                    (permisos && permisos.desactivar_admin) &&
                                    <td>
                                        <button className="btn btn-link" onClick={() => status(item._id, item.validado)}>{(item.validado === 1) ? <span className="text-danger">Desactivar</span> : <span className="text-primary">Activar</span>}</button>
                                    </td>
                                }
                                {
                                    (permisos && permisos.editar_admin) &&
                                    <td>
                                        <Link to={`/administrador/editar/${item._id}`}>Editar...</Link>
                                    </td>
                                }
                                {
                                    (permisos && permisos.eliminar_admin) &&
                                    <td>
                                        <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>
                                    </td>
                                }
                            </tr>
                        </Fragment>)
                    } else {
                        return (<Fragment key={item._id}>
                            <tr className="text-muted">
                                <td>{item.nombre}</td>
                                <td>{item.email}</td>
                                <td>{fecha.getDay()}/{fecha.getMonth() + 1}/{fecha.getFullYear()}</td>
                                <td colSpan="3" className="text-center">
                                    <Link to="/perfil" className="btn btn-link text-secondary">Ver perfil</Link>
                                </td>
                            </tr>
                        </Fragment>)

                    }

                })}
            </tbody>
        );
    }

    const status = (id, validado) => {
        (validado === 1) ? dispatch(validarUsuario(id, 2)) : dispatch(validarUsuario(id, 1));
    }

    const eliminar = id => {
        dispatch(eliminarUsuario(id));
    }

    const editar = id => {
        dispatch(editandoUsuario(id));
        history.push('/administrador/editar');
    }

    useEffect(() => {
        dispatch(buscarUsuarios());
    }, []);

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Administradores</h3>
                        <p className="text-muted"> {usuarios.length} administradores registrados</p>
                    </div>
                    <div className="col-md-4 text-right">
                        {
                            (permisos && permisos.agregar_admin) &&
                            <Link to="/administrador/nuevo" className="btn btn-primary text-right">Agregar Administrador</Link>
                        }
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">Correo</th>
                                <th scope="col">Fecha de registro</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        {tableRow(usuarios)}
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Usuarios;