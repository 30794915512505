
import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { eliminarEspecialidad, buscarEspecialidades, editandoEspecialidad } from '../../actions/especialidades';

const Especialidades = ({ history }) => {

    const dispatch = useDispatch();
    const especialidades = useSelector(state => state.especialidades);
    const { permisos } = useSelector(state => state.auth.usuario);

    const tableRow = (items) => {
        return (
            <tbody>
                {items.map(item => (
                    <Fragment key={item._id}>
                        <tr>
                            <td>{item.id}</td>
                            <td>{item.nombre}</td>
                            <td>{item.descripcion}</td>
                            {
                                permisos.editar_especialidades &&
                                <td>
                                    <button className="btn btn-link text-primary" onClick={() => editar(item._id)}>Editar</button>
                                </td>
                            }
                            {
                                permisos.eliminar_especialidades &&
                                <td>
                                    <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}>Eliminar</button>
                                </td>
                            }
                            <td>
                            </td>
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        );
    }

    const eliminar = (id) => {
        dispatch(eliminarEspecialidad(id));
    }

    const editar = (id) => {
        dispatch(editandoEspecialidad(id));
        history.push('/especialidades/editar');
    }

    useEffect(() => {
        dispatch(buscarEspecialidades());
    }, [])

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Especialidades</h3>
                        <p className="text-muted">{especialidades.length} especialidades registradas</p>
                    </div>
                    {
                        permisos && permisos.agregar_especialidades &&
                        <div className="col-md-4 text-right">
                            <Link to="/especialidades/nuevo" className="btn btn-primary text-right">Agregar Especialidad</Link>
                        </div>
                    }
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Descripcion</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {tableRow(especialidades)}
                </table>
            </div>
        </div>
    )
}


export default Especialidades;