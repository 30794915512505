import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { loadDashboard } from '../../actions/dashboard'

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#42B4D9' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoNumero: {
        fontSize: 20,
        margin: 0,
        fontWeight: 'bold'
    },
    infoText: {
        margin: 0,
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    },
    buttonIcon: {
        fontSize: 30,
    },
    bgImg: {
        height: '40vw',
        opacity: 0.08,
        position: 'absolute',
        left: '-320px',
        top: '10vh'
    },
}

const Dashboard = () => {

    const user = useSelector(state => state.auth.usuario)
    const dashboard = useSelector(state => state.dashboard)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadDashboard());
    }, [])

    return (
        dashboard.info ? <div className="row">
            <img src="logo.png" className="d-inline-block my-4" alt="logo" style={styles.bgImg}/>
            <div className="col-md-10 offset-md-1 mt-3">
                <div className="row">
                    <div className="col-md-2 pr-5">
                        <div className="row">
                            <div className="col-md-12 py-3">
                                <a href="/consultas" className="btn btn-light btn-block">
                                    <div className="col-md-12 py-2">
                                        <i className="fa fa-file-prescription" style={styles.buttonIcon} />
                                    </div>
                                    Consultas
                                </a>
                            </div>
                            <div className="col-md-12 py-3">
                                <a href="/medicos" className="btn btn-light btn-block">
                                    <div className="col-md-12 py-2">
                                        <i className="fa fa-user-md" style={styles.buttonIcon} />
                                    </div>
                                    Médicos
                                </a>
                            </div>
                            <div className="col-md-12 py-3">
                                <a href="/pacientes" className="btn btn-light btn-block">
                                    <div className="col-md-12 py-2">
                                        <i className="fa fa-users" style={styles.buttonIcon} />
                                    </div>
                                    Pacientes
                                </a>
                            </div>
                            <div className="col-md-12 py-3">
                                <a href="/colaboradores" className="btn btn-light btn-block">
                                    <div className="col-md-12 py-2">
                                        <i className="fa fa-user-tie" style={styles.buttonIcon} />
                                    </div>
                                    Colaboradores
                                </a>
                            </div>

                            <div className="col-md-12 py-3">
                                <a href="/pagos/medicos/pendientes" className="btn btn-light btn-block">
                                    <div className="col-md-12 py-2">
                                        <i className="fa fa fa-wallet" style={styles.buttonIcon} />
                                    </div>
                                    Pagos Pendientes
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <h4 style={styles.titulo} className="mb-4">
                                    Bievenido {user.name}
                                </h4>
                            </div>
                            <div className="col-md-5">
                                <div className="card bg-light">
                                    <div className="card-body">
                                        <div className="card-title mb-3">
                                            <h5 style={styles.titulo}>Ingresos</h5>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-6">
                                                <p style={styles.infoNumero}>{
                                                    new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(dashboard.info.ingreso_total)
                                                }</p>
                                                <p style={styles.infoText}>ingreso total</p>
                                            </div>
                                            <div className="col-md-6">
                                                <p style={styles.infoNumero}>{
                                                    new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(dashboard.info.ganancia_total)
                                                }</p>
                                                <p style={styles.infoText}>ganancia total</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="card bg-light">
                                    <div className="card-body">
                                        <div className="card-title mb-3">
                                            <h5 style={styles.titulo}>Consultas realizadas</h5>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-4">
                                                <p style={styles.infoNumero}>{dashboard.info.chats_actuales}</p>
                                                <p style={styles.infoText}>chats</p>
                                            </div>
                                            <div className="col-md-4">
                                                <p style={styles.infoNumero}>{dashboard.info.llamadas_actuales}</p>
                                                <p style={styles.infoText}>llamadas</p>
                                            </div>
                                            <div className="col-md-4">
                                                <p style={styles.infoNumero}>{dashboard.info.videollamadas_actuales}</p>
                                                <p style={styles.infoText}>videollamadas</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="card  bg-light mt-3">
                                    <div className="card-body">
                                        <div className="card-title mb-3">
                                            <h5 style={styles.titulo}>Usuarios</h5>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <p style={styles.infoNumero}>{dashboard.info.medicos_actuales}</p>
                                                <p style={styles.infoText}>médicos totales</p>
                                            </div>
                                            <div className="col-md-3">
                                                <p style={styles.infoNumero}>{dashboard.info.medicos_mes}</p>
                                                <p style={styles.infoText}>médicos este mes</p>
                                            </div>
                                            <div className="col-md-3">
                                                <p style={styles.infoNumero}>{dashboard.info.pacientes_actuales}</p>
                                                <p style={styles.infoText}>pacientes totales</p>
                                            </div>
                                            <div className="col-md-3">
                                                <p style={styles.infoNumero}>{dashboard.info.pacientes_mes}</p>
                                                <p style={styles.infoText}>pacientes este mes</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="card  bg-light mt-3">
                                    <div className="card-body">
                                        <div className="card-title mb-3">
                                            <h5 style={styles.titulo}>Colaboradores</h5>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-4">
                                                <p style={styles.infoNumero}>{dashboard.info.colaboradores_actuales}</p>
                                                <p style={styles.infoText}>colaboradores totales</p>
                                            </div>
                                            <div className="col-md-4">
                                                <p style={styles.infoNumero}>{dashboard.info.colaboradores_mes}</p>
                                                <p style={styles.infoText}>colaboradores este mes</p>
                                            </div>
                                            <div className="col-md-4">
                                                <p style={styles.infoNumero}>{dashboard.info.medicos_gestionados_colaborador}</p>
                                                <p style={styles.infoText}>médicos gestionados</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div ></div > : <div></div>

    )
}

export default Dashboard;