import axios from 'axios';

import { LOGIN_SUCCESS, LOGIN_FAILED, USER_LOADED, AUTH_ERROR, LOGOUT, EDITAR_PERFIL } from './types';

import setAuthToken from '../utils/setAuthToken';

import { setAlert } from './alert';
import { changeLoader } from './loader';

// Iniciar sesion
export const login = ({ email, password }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ email, password });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_auth/`, body, config);

        await dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
    } catch (error) {
        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        await dispatch({
            type: LOGIN_FAILED
        });
    }
}

// Cargar sesion
export const loadUser = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/admin_auth/`);

        await dispatch({
            type: USER_LOADED,
            payload: res.data.usuario
        });
        
    } catch (error) {
        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        await dispatch({
            type: AUTH_ERROR
        });
    }

}

// Cerrar sesion
export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    })
}

//Editar perfil
export const editarUsuario = (usuario) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(usuario);
    
    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_usuarios/editar/`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: EDITAR_PERFIL,
            payload: res.data.usuario
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        await dispatch(changeLoader(true));

        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}