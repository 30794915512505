import { AGREGAR_ESPECIALIDAD, EDITAR_ESPECIALIDAD, ELIMINAR_ESPECIALIDAD, BUSCAR_ESPECIALIDADES, EDITANDO_ESPECIALIDAD } from '../actions/types'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case AGREGAR_ESPECIALIDAD:
            return [
                ...state, payload
            ];
        case EDITAR_ESPECIALIDAD:
            state.find( especialidad => especialidad._id === payload).editing = false;
            return state;
        case EDITANDO_ESPECIALIDAD:
            state.find( especialidad => especialidad._id === payload).editing = true;
            return state;
        case ELIMINAR_ESPECIALIDAD:
            return state.filter( especialidad => especialidad._id !== payload.id )
        case BUSCAR_ESPECIALIDADES:
            return payload;
        default:
            return state;
    }
}