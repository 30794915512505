import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.css';

import { obtenerPaciente } from '../../actions/pacientes';

const styles = {
    profileImage: {
        height: 100, 
        width: 100,
        borderRadius: '100px',
        backgroundSize: 'cover', 
        objectFit: 'cover'
    },
    nonProfileImage: {
        backgroundColor: '#AEAEAE'
    }, 
    title: {
        color: '#42B4D9',
    },
    container: {
        maxHeight: '80vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingBottom: 50
    }
}

const Paciente = ({ id }) => {

    const dispatch = useDispatch();

    const { detalles } = useSelector(state => state.pacientes);

    const [paciente, setPaciente] = useState(null);

    useEffect(() => {
        if(id){
            dispatch(obtenerPaciente(id));
        }
    }, [id]);

    useEffect(() => {
        setPaciente(detalles);
    }, [detalles]);

    return (
        paciente ? <div style={styles.container}>
            <div className="d-flex justify-content-center">
                {
                    paciente.url_perfil ?
                        <img
                            style={styles.profileImage}
                            alt=""
                            className="my-3 text-center"
                            src={paciente.url_perfil}
                        ></img>
                    :
                        <div className="my-3 img-fluid" style={{...styles.profileImage, ...styles.nonProfileImage}}></div>
                }
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-md-12 text-center">
                    <h5>{paciente.nombre} {paciente.apellido}</h5>
                    <h6 className="text-muted">{paciente.email}</h6>
                </div>
                {/* <div className="col-md-12 my-2">
                    Aqui va el select del estado salu2
                </div> */}
            </div>
            <div className="row">
                <div className="col-md-12 my-2">
                    <p className="my-0 text-muted">Sexo</p>
                    <p className="my-0 font-weight-bold">{paciente.sexo === 'm' ? 'Hombre' : 'Mujer'}</p>
                </div>
                <div className="col-md-12 my-2">
                    <p className="my-0 text-muted">Fecha de nacimiento</p>
                    <p className="my-0 font-weight-bold">
                        {`${new Date(paciente.nacimiento).toLocaleDateString(
                            'es-MX',
                            { year: 'numeric', month: 'long', day: 'numeric' }
                        )}`}
                    </p>
                </div>
                <div className="col-md-12 mt-3">
                    <Link style={{
                        color: '#42B4D9',
                    }} to={`/perfil_paciente/${paciente._id}`}>Ver perfil completo...</Link>
                </div>
            </div>
        </div> : <div></div>
    )
}

export default Paciente;