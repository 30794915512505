import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { login } from '../../actions/auth';

const styles = {
    rowStyle: {
        height: '80vh',
    },
    bgImg: {
        height: '40vw',
        opacity: 0.05,
        position: 'absolute',
        left: '-220px',
    },
    btnIniciar: {
        background: '#42B4D9',
        borderColor: '#42B4D9'
    }    
}

const Login = () => {
    
    const dispatch = useDispatch();

    const { isAuthenticated } = useSelector(state => state.auth);

    const [loginData, setLoginData ] = useState({
        email: '',
        password: ''
    });

    // Redireccionamos si está autenticado
    if (isAuthenticated) {
        return <Redirect to="/dashboard"></Redirect>
    }

    const { email, password } = loginData;

    const onChange = (e) => {
        setLoginData({
            ...loginData, [e.target.name]: e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();
        dispatch(login({ email, password}));
    }
    
    return (
        <div className="row d-flex align-items-center" style={styles.rowStyle}>
            <img src="logo.png" className="d-inline-block my-4" alt="logo" style={styles.bgImg}/>
            <div className="col-md-6 offset-md-3">
                <div className="card bg-light ">
                    <div className="card-body">
                        <div className="text-center mb-5">
                            <img src="logo.png" className="d-inline-block my-4" alt="logo" height="45" />
                            <h4 className="card-title">¡Bienvenido a Medpacom!</h4>
                        </div>
                        <form onSubmit={submitForm} method="POST">
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Correo o nombre de usuario"
                                    name="email"
                                    value={email}
                                    onChange={e => onChange(e)}></input>
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Contraseña"
                                    name="password"
                                    value={password}
                                    onChange={e => onChange(e)}></input>
                            </div>
                            <div className="form-group text-right pt-3">
                                <button type="submit" className="btn btn-primary" style={styles.btnIniciar}>Iniciar sesión</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;