import React, { useState } from 'react'
import { connect } from 'react-redux';
import { agregarEspecialidad } from '../../actions/especialidades';
import PropTypes from 'prop-types';

const FormEspecialidad = ({ agregarEspecialidad, history }) =>{
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Hacemos las validaciones necesarias
        try {
            await agregarEspecialidad(especialidadData);
            history.push('/especialidades');
        } catch (error) {
            return false;
        }
    }
    
    const [especialidadData, setEspecialidadData ] = useState({
        nombre: '',
        descripcion: ''
    });
    
    const { nombre, descripcion } = especialidadData;
    
    const onChange = (e) => {
        setEspecialidadData({
            ...especialidadData, [e.target.name]: e.target.value
        })
    }

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Crear Especialidad</h3>
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Nombre" name="nombre" onChange={e => onChange(e) } value={nombre}></input>
                </div>
                <div className="form-group">
                    <textarea name="descripcion" placeholder="Descripción" id="" cols="30" rows="10" className="form-control" onChange={e => onChange(e) } value={descripcion}></textarea>
                </div>
                <button type="submit" className="btn btn-primary float-right" onClick={handleSubmit}>Crear</button>
            </form>
        </div>
    )
}

FormEspecialidad.propTypes = {
    agregarEspecialidad: PropTypes.func.isRequired,
}
    

export default connect(null, {
    agregarEspecialidad
})(FormEspecialidad);