
// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Auth
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT'
export const EDITAR_PERFIL = 'EDITAR_PERFIL'

// Especialidades
export const AGREGAR_ESPECIALIDAD = 'AGREGAR_ESPECIALIDAD';
export const ELIMINAR_ESPECIALIDAD = 'ELIMINAR_ESPECIALIDAD';
export const EDITANDO_ESPECIALIDAD = 'EDITANDO_ESPECIALIDAD';
export const EDITAR_ESPECIALIDAD = 'EDITAR_ESPECIALIDAD';
export const BUSCAR_ESPECIALIDADES = 'BUSCAR_ESPECIALIDADES';

// Usuarios
export const AGREGAR_USUARIO = 'AGREGAR_ESPECIALIDAD';
export const ELIMINAR_USUARIO = 'ELIMINAR_ESPECIALIDAD';
export const EDITANDO_USUARIO = 'EDITANDO_USUARIO';
export const BUSCAR_USUARIOS = 'BUSCAR_USUARIOS';
export const EDITAR_USUARIO = 'EDITAR_USUARIO';

// Médicos
export const MEDICOS = {
    BUSCAR: 'BUSCAR_MEDICOS',
    DETALLES: 'DETALLES_MEDICO', 
    EDITAR: 'EDITAR_MEDICO'
};

// Pacientes
export const PACIENTES = {
    BUSCAR: 'BUSCAR_PACIENTES',
    DETALLES: 'DETALLES_PACIENTE', 
    EDITAR: 'EDITAR_PACIENTE'
};

// Colaboradores
export const COLABORADORES = {
    BUSCAR: 'BUSCAR_COLABORADORES',
    DETALLES: 'DETALLES_COLABORADOR'
};

// Cobros
export const COBROS = {
    BUSCAR: 'BUSCAR_COBROS',
    FACTURA: 'OBTENER_FACTURA_COBROS'
};

// Pagos
export const PAGOS = {
    BUSCAR: 'BUSCAR_PAGOS',
    PAGAR: 'PAGAR_PAGOS',
    FACTURA: 'OBTENER_FACTURA_PAGOS'
};

// Pagos a colaboradores
export const PAGOS_COLABORADORES = {
    BUSCAR: 'BUSCAR_PAGOS_COLABORADORES',
    PAGAR: 'PAGAR_PAGOS_COLABORADORES',
    FACTURA: 'OBTENER_FACTURA_PAGOS_COLABORADORES'
};

// DASHBOARD
export const LOAD_DASHBOARD = 'LOAD_DASHBOARD'

// Consultas
export const CONSULTAS = {
    BUSCAR: 'LOAD_CONSULTAS',
    DETALLES: 'DETALLES_CONSULTA'
};

// Loader
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'
