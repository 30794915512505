import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import '@fortawesome/fontawesome-free/css/all.css';


const Sidebar = ({ history, location }) => {

    const { permisos } = useSelector(state => state.auth.usuario);

    const styles = {
        navStyles: { fontSize: '1rem', color: '#EEE', whiteSpace: 'nowrap' },
        submenuStyle: { width: '15rem' }
    }

    return (
        <SideNav
            style={{ backgroundColor: '#003559', position: 'fixed' }}
            onSelect={(selected) => {
                if (location.pathname !== selected) {
                    history.push(selected);
                }
            }}
        >
            <SideNav.Toggle />
            <SideNav.Nav defaultSelected="/dashboard">
                <NavItem eventKey="/dashboard">
                    <NavIcon>
                        <i className="fa fa-home" style={styles.navStyles} />
                    </NavIcon>
                    <NavText style={styles.navStyles}>
                        Dashboard
                    </NavText>
                </NavItem>
                {
                    permisos.admin &&
                    <NavItem eventKey="/administrador">
                        <NavIcon>
                            <i className="fa fa-user-shield" style={styles.navStyles} />
                        </NavIcon>
                        <NavText style={styles.navStyles}>
                            Administradores
                    </NavText>
                    </NavItem>
                }
                {
                    permisos.especialidades &&
                    <NavItem eventKey="/especialidades">
                        <NavIcon>
                            <i className="fa fa-stethoscope" style={styles.navStyles} />
                        </NavIcon>
                        <NavText style={styles.navStyles}>
                            Especialidades
                    </NavText>
                    </NavItem>
                }
                {
                    permisos.medicos &&
                    <NavItem eventKey="/medicos">
                        <NavIcon>
                            <i className="fa fa-user-md" style={styles.navStyles} />
                        </NavIcon>
                        <NavText style={styles.navStyles}>
                            Médicos
                    </NavText>
                    </NavItem>
                }
                {
                    permisos.pacientes &&
                    <NavItem eventKey="/pacientes">
                        <NavIcon>
                            <i className="fa fa-users" style={styles.navStyles} />
                        </NavIcon>
                        <NavText style={styles.navStyles}>
                            Pacientes
                    </NavText>
                    </NavItem>
                }
                {
                    permisos.colaboradores &&
                    <NavItem eventKey="/colaboradores">
                        <NavIcon>
                            <i className="fa fa-user-tie" style={styles.navStyles} />
                        </NavIcon>
                        <NavText style={styles.navStyles}>
                            Colaboradores
                    </NavText>
                    </NavItem>
                }
                {
                    permisos.consultas &&
                    <NavItem eventKey="/consultas">
                        <NavIcon>
                            <i className="fa fa-file-prescription" style={styles.navStyles} />
                        </NavIcon>
                        <NavText style={styles.navStyles}>
                            Consultas
                        </NavText>
                    </NavItem>
                }
                {
                    (permisos.cobros || permisos.pagos_pendientes || permisos.pagos_realizados || permisos.pagos_pendientes_colaborador || permisos.pagos_realizados_colaborador) &&
                    <NavItem subnavStyle={styles.submenuStyle}>
                        <NavIcon>
                            <i className="fa fa-wallet" style={styles.navStyles} />
                        </NavIcon>
                        <NavText style={styles.navStyles}>
                            Administración
                        </NavText>
                        {/* Submenú */}
                        {
                            permisos.pagos_pendientes &&
                            <NavItem eventKey="/pagos/medicos/pendientes">
                                <NavText>
                                    Pagos pendientes - Médico
                                </NavText>
                            </NavItem>
                        }
                        {
                            permisos.pagos_realizados &&
                            <NavItem eventKey="/pagos/medicos/realizados">
                                <NavText>
                                    Pagos relizados - Médico
                                </NavText>
                            </NavItem>
                        }
                        {
                            permisos.cobros &&
                            <NavItem eventKey="/pagos/pacientes">
                                <NavText>
                                    Cobros a pacientes
                                </NavText>
                            </NavItem>
                        }
                        {
                            permisos.pagos_pendientes_colaborador &&
                            <NavItem eventKey="/pagos/colaboradores/pendientes">
                                <NavText>
                                    Pagos pendientes - Colaborador
                                </NavText>
                            </NavItem>
                        }
                        {
                            permisos.pagos_realizados_colaborador &&
                            <NavItem eventKey="/pagos/colaboradores/realizados">
                                <NavText>
                                    Pagos relizados - Colaborador
                                </NavText>
                            </NavItem>
                        }
                    </NavItem>
                }
            </SideNav.Nav>
        </SideNav>

    )
}

export default withRouter(Sidebar);
