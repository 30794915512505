import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

// Layout
import Layout from './components/layout/Layout';
import Alert from './components/layout/Alert'
import NotFoundPage from './components/routing/NotFoundPage'
import PrivateRoute from './components/routing/PrivateRoute'

// Login
import Login from './views/auth/Login'

//Dashboard
import Dashboard from './views/dashboard/Dashboard'

// Especialidades
import Especialidades from './views/especialidades/Especialidades'
import FormEspecialidad from './views/especialidades/FormEspecialidad'
import EditEspecialidad from './views/especialidades/EditEspecialidad'

// Usuarios
import Perfil from './views/usuarios/Perfil'
import Usuarios from './views/usuarios/Usuarios'
import FormUsuario from './views/usuarios/FormUsuario'
import EditUsuario from './views/usuarios/EditUsuario'

// Cobros pacientes
import CobrosPacientes from './views/cobros_pacientes/CobrosPacientes'

// Pagos Medicos
import PagosMedicosRealizados from './views/pagos_medicos/PagosMedicosRealizados'
import PagosMedicosPendientes from './views/pagos_medicos/PagosMedicosPendientes'

// Pagos Colaboradores
import PagosColaboradoresRealizados from './views/pagos_colaboradores/PagosColaboradoresRealizados'
import PagosColaboradoresPendientes from './views/pagos_colaboradores/PagosColaboradoresPendientes'

// Medicos
import Medicos from './views/medicos/Medicos'
import PerfilMedico from './views/medicos/PerfilMedico'

// Pacientes
import Pacientes from './views/pacientes/Pacientes'
import PerfilPaciente from './views/pacientes/PerfilPaciente'

// Colaboradores
import Colaboradores from './views/colaboradores/Colaboradores'
import PerfilColaborador from './views/colaboradores/PerfilColaborador'

// Consultas
import Consultas from './views/consultas/Consultas'
import Consulta from './views/consultas/Consulta'

// Redux
import store from './store';
import setAuthToken from './utils/setAuthToken';
import { loadUser } from './actions/auth'

const App = () => {

    if(localStorage.token){
        setAuthToken(localStorage.token);
    }

    useEffect(() => {
        store.dispatch(loadUser());
    }, []);

    return (<Provider store={store}>
        <Router>
            <Fragment>
                <Layout></Layout>
                <section className="container-fluid" style={{ paddingLeft: '64px' }}>
                    <Alert></Alert>
                    <Switch>
                        <Route exact path="/" component={Login} />
                        <PrivateRoute exact path="/dashboard" component={Dashboard} />

                        {/* Especialidades */}
                        <PrivateRoute exact path="/especialidades" component={Especialidades} />
                        <PrivateRoute exact path="/especialidades/nuevo" component={FormEspecialidad} />
                        <PrivateRoute exact path="/especialidades/editar" component={EditEspecialidad} />

                        {/* Usuarios */}
                        <PrivateRoute exact path="/administrador" component={Usuarios} />
                        <PrivateRoute exact path="/administrador/nuevo" component={FormUsuario} />
                        <PrivateRoute exact path="/administrador/editar/:id" component={EditUsuario} />

                        {/* Perfil */}
                        <PrivateRoute exact path="/perfil" component={Perfil} />

                        {/* Médicos */}
                        <PrivateRoute exact path="/medicos" component={Medicos} />
                        <PrivateRoute exact path="/perfil_medico/:id" component={PerfilMedico} />

                        {/* Pacientes */}
                        <PrivateRoute exact path="/pacientes" component={Pacientes} />
                        <PrivateRoute exact path="/perfil_paciente/:id" component={PerfilPaciente} />

                        {/* Colaboradores */}
                        <PrivateRoute exact path="/colaboradores" component={Colaboradores} />
                        <PrivateRoute exact path="/perfil_colaborador/:id" component={PerfilColaborador} />

                        {/* Cobros pacientes */}
                        <PrivateRoute exact path="/pagos/pacientes" component={CobrosPacientes} />

                        {/* Pagos medicos */}
                        <PrivateRoute exact path="/pagos/medicos/pendientes" component={PagosMedicosPendientes} />
                        <PrivateRoute exact path="/pagos/medicos/realizados" component={PagosMedicosRealizados} />

                        {/* Pagos colaboradores */}
                        <PrivateRoute exact path="/pagos/colaboradores/pendientes" component={PagosColaboradoresPendientes} />
                        <PrivateRoute exact path="/pagos/colaboradores/realizados" component={PagosColaboradoresRealizados} />

                        {/* Consultas */}
                        <PrivateRoute exact path="/consultas" component={Consultas} />
                        <PrivateRoute exact path="/resumen_consulta/:id" component={Consulta} />

                        <Route path="*" component={NotFoundPage} />
                    </Switch>
                </section>
            </Fragment>
        </Router>
    </Provider>)
}

export default App;
