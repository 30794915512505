import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Collapse, Button, Modal } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import DataTable from 'react-data-table-component';
import '@fortawesome/fontawesome-free/css/all.css';
import { buscarConsultas } from '../../actions/consultas'
import { es } from 'react-date-range/dist/locale/index';

const Consultas = ({ history }) => {

    const [consultasFiltradas, setConsultasFiltradas] = useState([]);
    // Redux
    const dispatch = useDispatch();
    const { listado } = useSelector(state => state.consultas);
    const permisos = useSelector(state => state.auth.usuario.permisos)

    useEffect(() => {
        dispatch(buscarConsultas());
    }, [])

    // Tabla
    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };
    const columns = [{
        name: 'Medico',
        selector: 'medico',
        sortable: true,
        cell: item => `${item.nombre_medico} ${item.apellido_medico}`,
    }, {
        name: 'Paciente',
        selector: 'paciente',
        sortable: true,
        cell: item => `${item.nombre_paciente} ${item.apellido_paciente}`,
    }, {
        name: 'Tipo',
        selector: 'tipo_consulta',
        sortable: true,
        cell: item => {
            if (item.tipo_consulta === 0) {
                return 'Chat'
            } else if (item.tipo_consulta === 1) {
                return 'Llamada'
            } else {
                return 'Videollamada'
            }
        }
    }, {
        name: 'Fecha',
        selector: 'fecha',
        sortable: true,
        cell: item => `${new Intl.DateTimeFormat("es-MX").format(new Date(item.fecha))}`,
    }, {
        name: 'Monto',
        selector: 'monto',
        sortable: true,
        cell: item => `${new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.monto)}`,
    }, {
        name: 'Duracion',
        selector: 'duracion',
        sortable: true,
        cell: item => `${item.duracion}`,
    }, {
        name: '',
        button: true,
        omit: !permisos?.ver_consulta, 
        cell: item => <button className="btn btn-link text-info" onClick={() => { handleNavigate(item._id) }}>Ver más...</button>,
    }];

    const handleNavigate = id => {
        history.push(`/resumen_consulta/${id}`)
    }

    // Filtros
    const [filtrosVisible, setFiltrosVisible] = useState(false);
    const [medico, setMedico] = useState('');
    const [paciente, setPaciente] = useState('');
    const [dateRange, setDateRange] = useState({
        changed: false,
        selection: {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }
    });
    const [chat, setChat] = useState(true);
    const [llamada, setLlamada] = useState(true);
    const [videollamada, setVideollamada] = useState(true);
    
    const limpiarFiltros = () => {
        setDateRange({
            changed: false,
            selection: {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            }
        });
        setMedico('');
        setPaciente('');
    };
    const onChangeDateRange = range => {
        setDateRange({
            changed: true,
            selection: range.selection
        });
    }
    const onChangeMedico = medico => {
        setMedico(medico);
    }
    const onChangePaciente = paciente => {
        setPaciente(paciente);
    }

    useEffect(() => {
        filtrar();
    }, [listado])

    const filtrar = () => {
        let consultasTemp = listado;
        if (medico) {
            consultasTemp = consultasTemp.filter(consulta => {
                const value = `${consulta['nombre_medico'].toLowerCase()} ${consulta['apellido_medico'].toLowerCase()}`;
                if (value.includes(medico.toLowerCase())) {
                    return true;
                } else {
                    return false;
                }
            })
        }
        if (paciente) {
            consultasTemp = consultasTemp.filter(consulta => {
                const value = `${consulta['nombre_paciente'].toLowerCase()} ${consulta['apellido_paciente'].toLowerCase()}`;
                if (value.includes(paciente.toLowerCase())) {
                    return true;
                } else {
                    return false;
                }
            })
        }
        if (!chat) {
            consultasTemp = consultasTemp.filter(pago =>
                pago.tipo_consulta === 0 ? false : true
            )
        }
        if (!llamada) {
            consultasTemp = consultasTemp.filter(pago =>
                pago.tipo_consulta === 1 ? false : true
            )
        }
        if (!videollamada) {
            consultasTemp = consultasTemp.filter(pago =>
                pago.tipo_consulta === 2 ? false : true
            )
        }

        if (dateRange.changed) {
            consultasTemp = consultasTemp.filter(consulta => {
                if (dateRange.selection.startDate <= new Date(consulta.fecha) && new Date(consulta.fecha) <= dateRange.selection.endDate) {
                    return false;
                } else {
                    return true;
                }
            });
        }
        setConsultasFiltradas(consultasTemp);
    }

    return (
        <Fragment>
            <div className="row pb-5 mt-5">
                <div className="col-md-10 offset-md-1 mt-3">
                    <div className="col-md-12 my-3">
                        <Button
                            onClick={() => setFiltrosVisible(!filtrosVisible)}
                            variant="link"
                            aria-controls="filtros"
                            aria-expanded={filtrosVisible}
                            className="m-0 p-0 text-secondary"
                        >
                            Mostrar filtros
                            {
                                filtrosVisible ?
                                    <i className="fa fa-caret-down px-2"></i> :
                                    <i className="fa fa-caret-right px-2"></i>
                            }
                        </Button>
                        <Collapse in={filtrosVisible}>
                            <div className="row mt-4" id="filtros">
                                <div className="col-md-3">
                                    <div className="form-group my-0 has-search">
                                        <label className="font-weight-bold" htmlFor="nombreInput">Médico</label>
                                        <input
                                            type="text"
                                            className="form-control" placeholder="Médico"
                                            onChange={e =>
                                                onChangeMedico(e.target.value)
                                            }
                                            value={medico}></input>
                                    </div>
                                    <div className="form-group my-0 has-search">
                                        <label className="font-weight-bold" htmlFor="nombreInput">Paciente</label>
                                        <input
                                            type="text"
                                            className="form-control" placeholder="Paciente"
                                            onChange={e =>
                                                onChangePaciente(e.target.value)
                                            }
                                            value={paciente}></input>
                                    </div>
                                    <hr className="col-md-12" />
                                    <div className="form-group my-0 has-search">
                                        <label className="font-weight-bold">Tipo</label>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={chat}
                                                onChange={() => setChat(!chat)}
                                            ></input>
                                            <label className="form-check-label" htmlFor="conekta">
                                                Chat
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={llamada}
                                                onChange={() => setLlamada(!llamada)}
                                            ></input>
                                            <label className="form-check-label" htmlFor="conekta">
                                                Llamada
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={videollamada}
                                                onChange={() => setVideollamada(!videollamada)}
                                            ></input>
                                            <label className="form-check-label" htmlFor="conekta">
                                                Videollamada
                                            </label>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-9">
                                    <label className="font-weight-bold col-md-12" htmlFor="nombreInput">Fecha</label>
                                    <DateRangePicker
                                        ranges={[dateRange.selection]}
                                        onChange={range => onChangeDateRange(range)}
                                        locale={es}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <Button
                                        onClick={filtrar}
                                        variant="primary"
                                        className="px-3"
                                    >
                                        Filtrar
                                    </Button>
                                    <Button
                                        onClick={limpiarFiltros}
                                        variant="link"
                                        className="px-3"
                                    >
                                        Limpiar filtros
                                    </Button>
                                </div>
                                <hr className="col-md-12" />
                            </div>
                        </Collapse>
                    </div>

                    <div className="col-md-12">
                        <div className="row d-flex align-items-center mb-4">
                            <div className="col-md-6">
                                <h3>Consultas</h3>
                            </div>
                        </div>
                        <DataTable
                            highlightOnHover
                            data={consultasFiltradas}
                            columns={columns}
                            defaultSortField="title"
                            noHeader={true}
                            pagination
                            paginationComponentOptions={paginationOptions}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


export default Consultas;