import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import '@fortawesome/fontawesome-free/css/all.css';

import Colaborador from './Colaborador';

import { buscarColaboradores } from '../../actions/colaboradores';

const Colaboradores = ({ history }) => {

    const dispatch = useDispatch();

    const permisos = useSelector(state => state.auth.usuario.permisos);
    const { listado } = useSelector(state => state.colaboradores);

    // Estado de medico Seleccionado
    const [colaboradorSeleccionado, setColaboradorSeleccionado] = useState(undefined);

    // Estado del filtro
    const [filterText, setFilterText] = useState('');
    const [filterValue, setFilterValue] = useState('nombre');
    const [colaboradoresFiltrados, setColaboradoresFiltrados] = useState([]);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            selector: 'nombre',
            sortable: true,
            cell: item => `${item.nombre} ${item.apellido}`,
        }, {
            name: 'Correo',
            selector: 'correo',
            sortable: true,
            cell: item => item.email ? `${item.email}` : 'Sin correo',
        }, {
            name: 'Teléfono',
            selector: 'telefono',
            sortable: true,
            cell: item => item.celular ? `${item.celular}` : 'Sin teléfono',
        }, {
            name: '',
            button: true,
            omit: !permisos?.perfil_paciente, 
            cell: item => <button className="btn btn-link text-primary" onClick={() => {
                setColaboradorSeleccionado(item._id);
            }}> Perfil </button>,
        }
    ];

    const conditionalRowStyles = [
        {
            when: row => row._id === colaboradorSeleccionado,
            style: {
                backgroundColor: '#f8f9fa !important',
            },
        }
    ];

    const onChangeFilterText = text => {
        setFilterText(text);
        text = text.toLowerCase();
        
        // Filtrar cédula, nombre, apellido, especialidaes, 
        setColaboradoresFiltrados(listado.filter(colaborador => {
            const value = (colaborador[filterValue]) ? colaborador[filterValue].toLowerCase() : '';
            if (value.includes(text)) {
                return true;
            } else {
                return false;
            }
        }));
    }

    useEffect(() => {
        dispatch(buscarColaboradores());
    }, []);

    useEffect(() => {
        setColaboradoresFiltrados(listado);
    }, [listado]);

    return (
        <div>
            <div className="row">
                <div className={colaboradorSeleccionado ? 'col-md-8 mt-5' : 'col-md-8  offset-md-2 mt-5'}>
                    <div className="col-md-12">
                        <div className="row d-flex align-items-center mb-1">
                            <div className="col-md-6">
                                <h3>Colaboradores</h3>
                                <p className="text-muted mb-0 pb-0"> {listado.length} colaboradores registrados</p>
                            </div>
                            <div className="col-md-3 px-0">
                                <div className="form-group has-search">
                                    <span className="fa fa-search form-control-feedback" style={{
                                        position: 'absolute',
                                        zIndex: 2,
                                        display: 'block',
                                        width: '2.375rem',
                                        height: '2.375rem',
                                        lineHeight: '2.375rem',
                                        textAlign: 'center',
                                        pointerEvents: 'none',
                                        color: '#aaa'
                                    }}></span>
                                    <input
                                        type="text"
                                        className="form-control border-right-0" placeholder="Filtrar"
                                        value={filterText}
                                        onChange={e =>
                                            onChangeFilterText(e.target.value)
                                        }
                                        style={{
                                            paddingLeft: '2.375rem', borderTopRightRadius: 0,
                                            borderBottomRightRadius: 0
                                        }}></input>

                                </div>
                            </div>
                            <div className="col-md-3 px-0">
                                <div className="form-group">
                                    <select
                                        value={filterValue}
                                        className="form-control bg-light" style={{
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0
                                        }}
                                        onChange={e => {
                                            setFilterValue(e.target.value);
                                        }}
                                    >
                                        <option value="nombre">Nombre</option>
                                        <option value="apellido">Apellido</option>
                                        <option value="email">Correo</option>
                                        <option value="telefono">Teléfono</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                        <DataTable
                            columns={columns}
                            data={colaboradoresFiltrados}
                            defaultSortField="title"
                            noHeader={true}
                            pagination
                            paginationComponentOptions={paginationOptions}
                            conditionalRowStyles={conditionalRowStyles}
                        />
                    </div>
                </div>
                <div className={colaboradorSeleccionado ? 'col-md-4 bg-light' : 'd-none'}>
                    <div className="col-md-12 text-right">
                        <button className="btn" onClick={() => setColaboradorSeleccionado(undefined)}><i className="fa fa-times"></i></button>
                        <hr></hr>
                    </div>
                    <div className="col-md-12">
                        <Colaborador id={colaboradorSeleccionado}></Colaborador>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Colaboradores;