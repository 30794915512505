import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.css';

import { obtenerColaborador } from '../../actions/colaboradores';

const styles = {
    title: {
        color: '#42B4D9',
    },
    container: {
        maxHeight: '80vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingBottom: 50
    }
}

const Colaborador = ({ id }) => {

    const dispatch = useDispatch();

    const { detalles } = useSelector(state => state.colaboradores);

    const [colaborador, setColaborador] = useState(null);

    useEffect(() => {
        if(id){
            dispatch(obtenerColaborador(id));
        }
    }, [id]);

    useEffect(() => {
        setColaborador(detalles);
    }, [detalles]);

    return (
        colaborador ? <div style={styles.container}>
            <div className="row d-flex justify-content-center">
                <div className="col-md-12 text-center">
                    <h5>{colaborador.nombre} {colaborador.apellido}</h5>
                    <h6 className="text-muted">{colaborador.email}</h6>
                </div>
                {/* <div className="col-md-12 my-2">
                    Aqui va el select del estado salu2
                </div> */}
            </div>
            <div className="row">
                <div className="col-md-12 my-2">
                    <p className="my-0 text-muted">Médicos gestionados</p>
                    <p className="my-0 font-weight-bold">{colaborador.medicos_gestionados ? colaborador.medicos_gestionados : 0 }</p>
                </div>
                <div className="col-md-12 my-2">
                    <p className="my-0 text-muted">Fecha de registro</p>
                    <p className="my-0 font-weight-bold">
                        {`${new Date(colaborador.date).toLocaleDateString(
                            'es-MX',
                            { year: 'numeric', month: 'long', day: 'numeric' }
                        )}`}
                    </p>
                </div>
                <div className="col-md-12 mt-3">
                    <Link style={{
                        color: '#42B4D9',
                    }} to={`/perfil_colaborador/${colaborador._id}`}>Ver perfil completo...</Link>
                </div>
            </div>
        </div> : <div></div>
    )
}

export default Colaborador;