import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Button } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.css';

import { obtenerColaborador } from '../../actions/colaboradores';

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#42B4D9' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoNumero: {
        fontSize: 40,
        margin: 0,
    },
    infoText: {
        margin: 0,
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    }
}

const PerfilColaborador = ({ history }) => {

    const dispatch = useDispatch();

    const { detalles } = useSelector(state => state.colaboradores);

    const { id } = useParams();

    const [colaborador, setColaborador] = useState(null);

    const navigate = async (id) => {
        await setColaborador(null);
        await history.push(`/perfil_colaborador/${id}`);
    }

    useEffect(() => {
        if(id){
            dispatch(obtenerColaborador(id));
        }
    }, [id]);

    useEffect(() => {
        setColaborador(detalles);
    }, [detalles]);

    return (
        colaborador ? <div className="row py-5">
            <div className="col-md-3 px-5">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-12 text-center">
                        <h4 className="">{colaborador.nombre} {colaborador.apellido}</h4>
                        <h6 className="text-muted">En Medpacom desde {`${new Date(colaborador.date).toLocaleDateString(
                            'es-MX',
                            { year: 'numeric', month: 'long', day: 'numeric' }
                        )}`}</h6>
                        <h6 className="text-muted">{colaborador.email}</h6>
                    </div>
                </div>
            </div>
            <div className="col-md-9">
                <div className="row">
                    <div className="col-md-12 px-4">
                        <div className="row">
                            <div className="col-md">
                                <p style={styles.infoNumero}>{colaborador.medicos_gestionados ? colaborador.medicos_gestionados : 0}</p>
                                <p style={styles.infoText}>médicos gestionados</p>
                            </div>
                            <div className="col-md">
                                <p style={styles.infoNumero}>{
                                    new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(colaborador.generado)
                                }</p>
                                <p style={styles.infoText}>generado</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-12">
                        <Accordion defaultActiveKey="0">
                            <div className="row">
                                <div className="col-md-12">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        <h5 style={styles.titulo}>
                                            Información Personal
                                            <i className="fa fa-caret-right pl-3"></i>
                                        </h5>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <div style={styles.sectionContainer}>
                                            <p style={styles.subtitulo}>Nombre</p>
                                            <p style={styles.info}>{colaborador.nombre} {colaborador.apellido}</p>
                                            <p style={styles.subtitulo}>Fecha de registro</p>
                                            <p style={styles.info}>{new Date(colaborador.date).toLocaleDateString('es-MX')}</p>
                                            <p style={styles.subtitulo}>Correo</p>
                                            <p style={styles.info}>{colaborador.email}</p>
                                            <p style={styles.subtitulo}>Teléfono</p>
                                            <p style={styles.info}>{colaborador.celular}</p>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            </div>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div > : <div></div>
    )
}



export default PerfilColaborador;
