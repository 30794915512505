import axios from 'axios';

import { COLABORADORES } from '../actions/types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

export const buscarColaboradores = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
    }

    try {
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/admin_colaboradores/`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.BUSCAR,
            payload: res.data.colaboradores
        });
    } catch (error) {
        await dispatch(changeLoader(false));

        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const obtenerColaborador = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    };

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/admin_colaboradores/ver`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.DETALLES,
            payload: res.data.colaborador
        })
    } catch (error) {
        await dispatch(changeLoader(false));

        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}