import axios from 'axios';

import { LOAD_DASHBOARD } from './types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

// LOAD USER
export const loadDashboard = () => async dispatch => {
    try {
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/admin_dashboard`);
        await dispatch(changeLoader(false));
        
        await dispatch({
            type: LOAD_DASHBOARD,
            payload: {
                info: res.data
            }
        });
    } catch (error) {
        await dispatch(changeLoader(false));

        if (error?.response?.data?.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}